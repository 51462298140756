import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import GoogleSignIn from "../../components/GoogleSignIn";
import ErrorModal from "../ErrorModal";
import { getSession } from "../../utilites/SessionFns";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";

import laptopImg from "../../assets/images/laptop.png";
import "./welcome-section-style.css";
import LoginPopup from "../LoginPopup";

const WelcomeSection = ({
  loginFlag,
  userId,
  onContinueClick,
  refferalDetails,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginPopup, setLoginPopup] = useState(false);

  const history = useHistory();
  const onLogout = useGoogleLogoutHook();

  const showErrorModal = (code = "") => {
    setErrorMessage(
      setErrorMessage({
        message: "Error Occured while Login in Please try again.",
        code: code,
      })
    );
    setModalVisible(true);
    onLogout();
  };

  let userData = getSession("userDetails");
  let appliedCount = userData?.jobApplicationDetails?.length;
  userData?.jobApplicationDetails?.map((obj) => {
    if (
      obj.status.toLowerCase() === "cancelled" ||
      obj.status.toLowerCase() === "rejected"
    ) {
      appliedCount -= 1;
    }
  });
  let manageIsVisible = appliedCount > 0 ?? false;

  const isLoginMessage = () => {
    return userId ? (
      <>
        {appliedCount > 0 ? (
          <p>
            {/* Dear {userData?.formData?.name} <br /> */}
            Manage your application
          </p>
        ) : (
          <p>
            {/* Dear {userData?.formData?.name} <br /> */}
            Continue your journey with us
          </p>
        )}

        <div
          className="welcome-login-btn"
          style={{ visibility: manageIsVisible ? "visible" : "hidden" }}
          onClick={() => history.push("/manage")}
        >
          <span>Manage</span>
        </div>
      </>
    ) : (
      <>
        {refferalDetails?.referralPageConfigurations?.bannerSubHeading ? (
          <p>
            {/* ALREADY APPLIED? Sign in to <br />
          continue your journey with us */}
            {refferalDetails?.referralPageConfigurations?.bannerSubHeading}
          </p>
        ) : (
          <p>
            Already applied? Sign in to <br />
            continue your journey with us.
          </p>
        )}

        {loginFlag ? (
          <div
            className="welcome-login-btn"
            // style={{ visibility: "hidden" }}
            onClick={() => history.push("/sign-up")}
          >
            <span>Register</span>
          </div>
        ) : (
          <div className="welcome-login-btn">
            <GoogleSignIn
              icon={true}
              btnClassName={true}
              showPopup={showErrorModal}
              googleBtnText="Sign in with Google"
              loginSuccess={() => {}}
              userNotSignIn={(data) => {}}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      {modalVisible ? (
        <ErrorModal
          errormessage={errorMessage}
          close={() => {
            setModalVisible(false);
          }}
          visibile={modalVisible}
        />
      ) : null}
      <div className="main-container">
        <div className="welcome-container">
          <div className="welcome-login-container">
            <p className="hi-user-text">
              {userData?.formData?.name ? `Hi ${userData?.formData?.name}` : ""}
            </p>
            {refferalDetails?.referralPageConfigurations?.bannerHeading ? (
              <h2>
                Welcome to <br />
                {refferalDetails?.referralPageConfigurations?.bannerHeading}
              </h2>
            ) : (
              <h2>
                Welcome to <br />
                Tekno Point Hiring Portal.
              </h2>
            )}

            {isLoginMessage()}
          </div>

          <div className="welcome-sign-up-container">
            <div className="sign-up-content">
              {/* <p>
                Got 0 to 2 years of experience? <br /> Start the selection
                process below.
              </p> */}
              {refferalDetails?.referralPageConfigurations
                ?.bannerDescription ? (
                <p>
                  {/* You are just 5 steps away from your dream job! <br /> Start the
                selection process below. */}
                  {
                    refferalDetails?.referralPageConfigurations
                      ?.bannerDescription
                  }
                </p>
              ) : // {
              //   appliedCount===1?<p>
              //     You have applied for 1 job, you are just {userId ? "5" : "6"}  Steps away to apply for 1 more
              //   </p>:appliedCount>1?null:<p>
              //   You are just {userId ? "5" : "6"} steps away from your dream
              //   job!
              // </p>
              // }

              appliedCount === 1 ? (
                <p>
                  You have applied for 1 job, you are just {userId ? "5" : "6"}{" "}
                  Steps away to apply for 1 more
                </p>
              ) : appliedCount > 1 ? (
                <p style={{ visibility: "hidden" }}>
                  You have applied for 1 job, you are just {userId ? "5" : "6"}{" "}
                  Steps away to apply for 1 more
                </p>
              ) : (
                <p>
                  You are just {userId ? "5" : "6"} steps away from your dream
                  job!
                </p>
              )}

              {loginFlag ? (
                appliedCount > 1 ? null : (
                  <span
                    onClick={() => onContinueClick()}
                    className="ws-continue-btn"
                  >
                    Continue
                  </span>
                )
              ) : (
                <div className="welcome-sign-up-btn">
                  <GoogleSignIn
                    googleBtnText="Get Started"
                    showPopup={showErrorModal}
                    loginSuccess={() => {}}
                    userNotSignIn={(data) => {
                      setLoginPopup(data);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="laptop-img-container">
              <img src={laptopImg} alt="laptop" />
            </div>
          </div>
        </div>
      </div>
      <LoginPopup
        visibile={loginPopup}
        modelError={() => showErrorModal()}
        setLoginPopup={setLoginPopup}
        googleBtnText="Sign in"
        loginStatus={(data) => {
          setLoginPopup(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginFlag: state.loginFlag,
  userId: state.formData.userId,
});

export default connect(mapStateToProps)(WelcomeSection);
