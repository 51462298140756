import React from "react";
import { connect } from "react-redux";

//Styles
import "./loader-style.css";

//Images
import LoaderIcon from "../../assets/images/loaderIcon.png";

//Component
import Modal from "../Modal";

function Loader({ visibile, isLoading }) {
  return (
    <Modal visibile={isLoading} style={{ backgroundColor: "rgb(0 0 0 / 60%)" }}>
      <div className="loader-container">
        <img src={LoaderIcon} alt="loading-icon" className="loading-img" />
        <p>LOADING...</p>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(Loader);
