import { useCallback } from "react";
import { useGoogleLogout } from "react-google-login";

const useGoogleLogoutHook = () => {
  // const clientId =
  //   "571067888966-jcbdnvmr8ufe6u5vcbamrqbctjgdgrua.apps.googleusercontent.com";

  const clientId =
    "637837369163-f2d0b04nlfo7smtfkuts9qa5v4dh7sum.apps.googleusercontent.com";

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess: (res) => console.log("Sucess", res),
    onFailure: (res) => console.log("Failure", res),
  });

  const onLogout = useCallback(() => {
    sessionStorage.clear();
    signOut();
  }, []);

  return onLogout;
};

export default useGoogleLogoutHook;
