import React from "react";
import useCustomGoogleLogin from "../../custom-hooks/useCustomGoogleLogIn";

export const CustomGoogleLoginButton = ({
  clientId,
  scope,
  onSuccess,
  onFailure,
  prompt,
  render,
}) => {
  const { getRequestAccessToken } = useCustomGoogleLogin({
    clientId,
    scope,
    onSuccess,
    onFailure,
    prompt,
  });

  if (render) {
    return render({ onClick: getRequestAccessToken });
  }
  return (
    <div>
      <button onClick={getRequestAccessToken}>Click me to login</button>
    </div>
  );
};
