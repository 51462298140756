import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import axios from "axios";

import ErrorModal from "../ErrorModal";
import notificationIconTwo from "../../assets/images/notification-icon-two.svg";
import { updateIsLoading } from "../../redux/actions/isLoading-action";
import { setloginFlag } from "../../redux/actions/login-flag-action";
import {
  updateFormData,
  formDataObj,
} from "../../redux/actions/form-data-action";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";
import { getSession } from "../../utilites/SessionFns";

import "./notification-style.css";

function Notification({
  isMessageRead = false,
  userId,
  updateIsLoading,
  setloginFlag,
  updateFormData,
}) {
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [newNotification, setNewNotification] = useState(false);

  const dropdownContainerRef = useRef();
  const history = useHistory();
  let onLogout = useGoogleLogoutHook();

  const userData = getSession("userDetails");

  const updateNotificationStatus = (obj) => {
    let resendObj = {
      notificationId: obj.notificationId,
    };

    updateIsLoading(true);

    axios
      .post("/shaft/api/job-applications/update-notifications/v1", resendObj, {
        headers: { Authorization: userData?.jwtToken },
      })
      .then(function (resp) {
        updateIsLoading(false);
        if (resp.data.status === "success") {
          if (resp.data.response.status) {
            getNotificationFunction();

            history.replace({
              pathname: "/manage",
              state: {
                notificationState: obj,
              },
            });
          }
        } else {
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0020-B]",
          });
          setModalVisible(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message: "Session timeout. Please login again.",
            code: "[U-0021-A]",
          });
          updateFormData(formDataObj);
          setloginFlag(false);
          onLogout();
        } else {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0021-A]",
          });
        }
      });
  };

  const getNotificationFunction = useCallback(() => {
    const form_data = new FormData();
    form_data.append("userId", userData?.formData?.userId ?? userId);

    updateIsLoading(true);
    axios
      .post(
        "/shaft/api/job-applications/get-notification-message/v1",
        form_data,
        {
          headers: { Authorization: userData?.jwtToken },
        }
      )
      .then(function (resp) {
        updateIsLoading(false);
        if (resp.data.status === "success") {
          let notifications = resp.data.response.notifications;

          let allNotificationArr = Object.keys(notifications).map((key) => {
            return notifications[key].map((obj) => ({
              ...obj,
              applicationId: key,
            }));
          });

          let notificationArr = allNotificationArr.flatMap((curr) => curr);

          let newNotificationArr = notificationArr.filter(
            (value) => value.readFlag.toLowerCase() === "no"
          );
          if (newNotificationArr.length === 0) {
            setNewNotification(false);
          } else {
            setNewNotification(true);
          }

          setNotificationData(notificationArr.reverse());
        } else {
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0003-B]",
          });
          setModalVisible(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message: "Session Timeout, Please login again.",
            code: "[U-0003-A]",
          });
          updateFormData(formDataObj);
          setloginFlag(false);
          onLogout();
        } else {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0003-A]",
          });
        }
      });
  }, [
    onLogout,
    setloginFlag,
    updateFormData,
    updateIsLoading,
    userData?.formData?.userId,
    userData?.jwtToken,
    userId,
  ]);
  useEffect(() => {
    getNotificationFunction();

    // On Body Click
    const onBodyClick = (e) => {
      if (dropdownContainerRef.current) {
        if (!dropdownContainerRef.current.contains(e.target)) {
          setNotificationDropDown(false);
        }
      }
    };

    document.body.addEventListener("click", onBodyClick);

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, [getNotificationFunction]);

  return (
    <div>
      {modalVisible ? (
        <ErrorModal
          errormessage={errormessage}
          close={() => {
            setModalVisible(false);
            if (errormessage?.message?.includes("Session")) {
              history.push("/");
            }
          }}
          visibile={modalVisible}
        />
      ) : null}

      <div
        className="header-user-info-container"
        ref={dropdownContainerRef}
        onClick={() => {
          setNotificationDropDown(!notificationDropDown);
        }}
      >
        <div className="header-user-icon-container">
          {newNotification ? (
            <div className="header-new-notification-shown"></div>
          ) : null}
          <img
            className={newNotification ? "notification-icon-animation" : null}
            style={{
              borderRadius: "50%",
              padding: "4px",
            }}
            src={notificationIconTwo}
            alt="notifications"
          />
        </div>

        <div
          className={`header-notification-dropdown-container ${
            notificationDropDown ? "header-notification-dropdown-active" : ""
          }`}
        >
          <div className="header-notification-box">
            <div className="header-notification-heading-container">
              <h6>Notification</h6>
            </div>

            <Scrollbars autoHide>
              <div className="header-notification-message-container">
                <ul className="header-notification-message-container-list">
                  {notificationData.map((obj) => (
                    <li
                      key={obj.notificationId}
                      onClick={() => updateNotificationStatus(obj)}
                      className={`header-notification-message-container-item ${
                        isMessageRead ? "notification-read" : ""
                      }`}
                      style={{
                        backgroundColor:
                          obj.readFlag === "NO" ? "#c7c7c761" : null,
                      }}
                    >
                      <div className="notification-cricle" />
                      <p className="notification-message">{obj.message}</p>
                      <p className="notification-application">{`~ ${obj.applicationId}`}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginFlag: state.loginFlag,
  isLoading: state.isLoading,
  jwtToken: state.jwtToken,
  userId: state.formData.userId,
});

export default connect(mapStateToProps, {
  setloginFlag,
  updateFormData,
  updateIsLoading,
})(Notification);
