import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import "./RefHeader-style.css";
import RefHeaderPopup from "../RefHeaderPopup";
const RefHeader = ({ refferalDetails, refferalName }) => {
  let history = useHistory();
  const [headerPopup, setHeaderPopup] = useState(false);
  return (
    <div className="refLogo-container">
      {setHeaderPopup ? (
        <RefHeaderPopup
          visibile={headerPopup}
          close={() => {
            setHeaderPopup(false);
          }}
        ></RefHeaderPopup>
      ) : null}
      <img
        src={refferalDetails?.logo}
        className="ref-logo"
        alt=""
        onClick={() => {
          history.push(`/referral/${refferalName}`);
        }}
        style={{ cursor: "pointer" }}
      ></img>
      <h3 style={{ fontWeight: "normal" }}>
        Powered by{" "}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => setHeaderPopup(true)}
        >
          HireStar
        </strong>
      </h3>
    </div>
  );
};
export default RefHeader;
