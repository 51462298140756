
import { LOGIN_FLAG } from "../actions/login-flag-action";

const INITIAL_STATE = false;

const loginFlagReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case LOGIN_FLAG:
         return action.payload
      default:
         return state;
   }
}

export default loginFlagReducer;