import { useEffect, useState, useCallback } from "react";

import Loadable from "react-loadable";

import { Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Header from "./components/Header";
import Home from "./container/Home";
import Footer from "./components/Footer";
// import InterviewProcess from "./container/InterviewProcess";
import PopVideo from "./components/PopVideo";
// import ThankYou from "./components/ThankYou";
// import Registration from "./components/RegistrationForm";
// import Profile from "./components/Profile";
import ScrollToTop from "./components/ScrollToTop";
import { updateJwtToken } from "./redux/actions/jwtToken-action";
import { setloginFlag } from "./redux/actions/login-flag-action";
import { getSession, setSession } from "./utilites/SessionFns";
import { updateFormData, formDataObj } from "./redux/actions/form-data-action";
import { updateIsLoading } from "./redux/actions/isLoading-action";
import useGoogleLogoutHook from "./custom-hooks/useGoogleLogoutHook";
import ErrorModal from "./components/ErrorModal";
import Loader from "./components/Loader";
// import ApplicationSuccessfulModal from "./components/ApplicationSuccessfulModal";
import "./App.css";
// import OtpVerificationModal from "./components/OTPVerificationModal";
import PageNotFound from "./components/PageNotFound";

//styles
// import "./components/InterviewProcessHeader/interview-process-header.css";

import SimpleLoader from "./components/SimpleLoader";
// import SubDomainRedirect from "./components/SubDomainRedirect/SubDomainRedirect";
import convertJsonToFormData from "./utilites/convertJsonToFormData";
import { deleteCookie, getCookie } from "./utilites/cookieStore";
// import { useHistory } from "react-router-dom";

const InterviewProcess = Loadable({
  loader: () => import("./container/InterviewProcess"),
  loading: SimpleLoader,
});

const ThankYou = Loadable({
  loader: () => import("./components/ThankYou"),
  loading: SimpleLoader,
});

const Registration = Loadable({
  loader: () => import("./components/RegistrationForm"),
  loading: SimpleLoader,
});

const Profile = Loadable({
  loader: () => import("./components/Profile"),
  loading: SimpleLoader,
});

const SubDomainRedirect = Loadable({
  loader: () => import("./components/SubDomainRedirect"),
  loading: SimpleLoader,
});

function App({
  updateJwtToken,
  setloginFlag,
  updateFormData,
  updateIsLoading,
  isLoading,
}) {
  const [refferalName, setRefferalName] = useState(
    () => getSession("refferal") ?? ""
  );

  const googleProfileDetails = JSON.parse(getCookie("googleProfileDetails"));

  const history = useHistory();

  const [errormessage, setErrorMessage] = useState("Its Error");
  const [modalVisible, setModalVisible] = useState(false);
  const [refferalDetails, setRefferalDetails] = useState(null);

  const onLogout = useGoogleLogoutHook();

  const fetchUserReferral = useCallback(
    (value) => {
      const userData = getSession("userDetails");
      let domainCondition = ["localhost", "hirestar", "uat", "www"].includes(
        window.location.hostname.split(".")[0]
      );
      const subDomainValue = window.location.hostname.split(".")[0];
      let fetchReqObj = {
        // refferalCode: refferalName,
        refferalType: domainCondition
          ? "refferalCode"
          : "refferalCodeAndSubdomain",
        refferalValue: value,
        subDomain: domainCondition ? "" : subDomainValue,
      };

      updateIsLoading(true);

      axios
        .post(
          "/shaft/api/referral-management/get-referral-url/v1",
          fetchReqObj,

          {
            headers: { Authorization: userData?.jwtToken },
          }
        )
        .then((resp) => {
          updateIsLoading(false);
          if (resp.data.status === "success") {
            // setRefLogo(resp.data.response.refferalDetails.logo);
            // setInitialState(resp.data.response.referralDetails);

            let refferalDetails = resp.data.response.refferalDetails[0]
              ? resp.data.response.refferalDetails[0]
              : [];
            setRefferalDetails(refferalDetails);
            if (
              refferalDetails?.refferalType?.toLowerCase() === "institutional"
            ) {
              document.title =
                refferalDetails.referralPageConfigurations.pageTitle;
            } else {
              document.title = "Tekno Hiring Portal";
            }
          } else {
            setModalVisible(true);
            // setErrorMessage(
            //   "We are facing some technical issue, please again try after some time.(U-0019-B)"
            // );
            setErrorMessage({
              message:
                "We are facing some technical issue, please again try after some time.",
              code: "[U-0019-B]",
            });
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            updateIsLoading(false);
            setModalVisible(true);
            // setErrorMessage("Session Timeout, Please login again.(U-0019-A)");
            setErrorMessage({
              message: "Session Timeout, Please login again.",
              code: "[U-0019-A]",
            });
            updateFormData(formDataObj);
            setloginFlag(false);
            onLogout();
          } else {
            updateIsLoading(false);
            setModalVisible(true);
            // setErrorMessage(
            //   "We are facing some technical issue, please again try after some time.(U-0019-A)"
            // );
            setErrorMessage({
              message:
                "We are facing some technical issue, please again try after some time.",
              code: "[U-0019-A]",
            });
          }
        });
    },
    [onLogout, setloginFlag, updateFormData, updateIsLoading]
  );

  useEffect(() => {
    if (refferalName) {
      fetchUserReferral(refferalName);
    }
  }, [refferalName, fetchUserReferral]);

  useEffect(() => {
    const userData = getSession("userDetails");
    setloginFlag(userData ? userData?.loginFlag ?? false : false);
    updateJwtToken(userData ? userData?.jwtToken ?? null : null);
    updateFormData(userData?.formData ?? formDataObj);
  }, [setloginFlag, updateJwtToken, updateFormData]);

  const referralLogin = (profileDetails) => {
    let initialUserDetails = getSession("userDetails");

    let data = {
      name: profileDetails?.name ? profileDetails?.name : "",
      email: profileDetails?.email ? profileDetails?.email : "",
      profilePic: profileDetails?.imageUrl ? profileDetails?.imageUrl : "",
      googleId: profileDetails?.googleId ? profileDetails?.googleId : "",
    };

    const form_data = convertJsonToFormData(data);

    updateIsLoading(true);
    axios
      .post("/shaft/api/user/login/v1", form_data)
      .then((resp) => {
        updateIsLoading(false);

        let userDetails = {
          ...initialUserDetails,
          formData: {
            email: profileDetails?.email ? profileDetails?.email : "",
            name: profileDetails?.name ? profileDetails?.name : "",
            profilePic: profileDetails?.imageUrl
              ? profileDetails?.imageUrl
              : "",
          },
          jwtToken: resp.data.response.tokenDetails.jwtToken,
          loginFlag: true,
        };
        setSession(userDetails);

        if (resp.data.status === "success") {
          setloginFlag(true);
          if (resp.data.response.userExists) {
            updateJwtToken(resp.data.response.tokenDetails.jwtToken);
            let userData = getSession("userDetails");

            setSession({
              ...userData,
              jwtToken: resp.data.response.tokenDetails.jwtToken,
            });

            if ("googleBtnText".toLowerCase() === "sign up with google") {
              // userNotSignIn(true);
              // deleteData();
            } else {
              const emailFormData = new FormData();
              emailFormData.append(
                "email",
                profileDetails?.email ? profileDetails?.email : ""
              );

              updateIsLoading(true);
              axios
                .post(
                  "/shaft/api/user/user-details-based-on-email-Id/v1",
                  emailFormData,
                  {
                    headers: {
                      Authorization: resp.data.response.tokenDetails.jwtToken,
                    },
                  }
                )
                .then((userObj) => {
                  updateIsLoading(false);
                  if (userObj.data.status === "success") {
                    let setUserInfo = getSession("userDetails");
                    setSession({
                      ...setUserInfo,
                      jobApplicationDetails:
                        userObj.data.response.jobApplicationDetails,
                      formData: {
                        ...userObj.data.response.userDetails,
                        profilePic: profileDetails?.imageUrl
                          ? profileDetails?.imageUrl
                          : "",
                      },
                    });
                    updateFormData(userObj.data.response.userDetails);
                    deleteCookie("googleProfileDetails");
                  } else {
                    setModalVisible(true);
                    setErrorMessage({
                      message:
                        "Something went wrong,our team is working on it. Please try again.",
                      code: "[U-0002-B]",
                    });
                  }
                })
                .catch((err) => {
                  if (err.response?.status === 401) {
                    updateIsLoading(false);
                    setModalVisible(true);
                    setErrorMessage({
                      message: "Session Timeout, Please login again.",
                      code: "[U-0002-A]",
                    });
                    updateFormData(formDataObj);
                    setloginFlag(false);
                    onLogout();
                  } else {
                    updateIsLoading(false);
                    setModalVisible(true);
                    setErrorMessage({
                      message:
                        "Something went wrong,our team is working on it. Please try again.",
                      code: "[U-0002-A]",
                    });
                  }
                });
            }
          } else {
            updateIsLoading(false);
            history.push("/sign-up");
          }
        } else {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0001-B]",
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message: "Session Timeout, Please login again.",
            code: "[U-0001-A]",
          });
          updateFormData(formDataObj);
          setloginFlag(false);
          onLogout();
        } else {
          updateIsLoading(false);
          setModalVisible(true);
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0001-A]",
          });
        }
        setModalVisible(true);
        setErrorMessage({
          message:
            "We are facing some technical issue, please again try after some time.",
          code: "[U-0001-A]",
        });
      });
  };

  useEffect(() => {
    if (googleProfileDetails?.profileDetails) {
      sessionStorage.setItem(
        "googleApiDetails",
        JSON.stringify(googleProfileDetails)
      );
      referralLogin(googleProfileDetails?.profileDetails);
    }
  }, []);

  return (
    <>
      {/* <HashRouter> */}
      {modalVisible ? (
        <ErrorModal
          errormessage={errormessage}
          close={() => setModalVisible(false)}
          visibile={modalVisible}
        />
      ) : null}
      {isLoading ? <Loader visibile={isLoading} /> : null}
      <ScrollToTop />
      <Header
        refferalDetails={refferalDetails}
        refferalName={refferalName}
        setRefferalName={setRefferalName}
      />
      <Switch>
        {/* <Route path={["/referral/:refferalCode", "*"]}>

          </Route> */}
        <Route exact path={["/referral/:refferalCode", "/emp/:empCode", "/"]}>
          <Home
            fetchUserReferral={fetchUserReferral}
            refferalDetails={refferalDetails}
            refferalName={refferalName}
            setRefferalName={setRefferalName}
            // redirectRefferal={redirectRefferal}
          />
          {/* <SignupPop visibile={true}/> */}
          {/* <OtpVerificationModal /> */}
        </Route>
        <Route path="/interview-process/:language">
          <InterviewProcess />
        </Route>
        <Route path="/pop-video">
          <PopVideo isLinkValid={false} isVideoValid={false} />
        </Route>
        <Route path="/manage">
          <ThankYou />
        </Route>
        <Route path="/sign-up">
          <Registration />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/login/:referralId">
          <SubDomainRedirect />
        </Route>
        {/* <Route path="/code-editor">
            <CodeEditor />
          </Route> */}
        <Route path="*">
          <PageNotFound />
        </Route>

        {/* <Chat /> */}
      </Switch>
      <Footer />

      {/* </HashRouter> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginFlag: state?.loginFlag,
  isLoading: state?.isLoading,
});

export default connect(mapStateToProps, {
  updateFormData,
  updateJwtToken,
  setloginFlag,
  updateIsLoading,
})(App);
