import { useState } from "react";

import Button from "../Button";

import gitIcon from "../../assets/images/git-icon.svg";
import lightCheckmarkIcon from "../../assets/images/light-checkmark-icon.svg";
import uploadIcon from "../../assets/images/upload-icon.svg";
import playButton from "../../assets/images/playButton.svg";
import redCloseIcon from "../../assets/images/red-close-icon.svg";
import "./pop-video-style.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import convertJsonToFormData from "../../utilites/convertJsonToFormData";
import { connect } from "react-redux";
import ErrorModal from "../ErrorModal";
import PopupTraningVideo from "../PopupTraningVideo";
import {
  updateFormData,
  formDataObj,
} from "../../redux/actions/form-data-action";
import Modal from "../Modal";
import { setloginFlag } from "../../redux/actions/login-flag-action";
import { updateIsLoading } from "../../redux/actions/isLoading-action";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";
import ApplicationSuccessfulModal from "../ApplicationSuccessfulModal";
import assThumbnail from "../../assets/images/assignmentThumbnail.svg";
import selfThumbnail from "../../assets/images/selfThumbnail.svg";
const PopVideo = (props) => {
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  let userData = JSON.parse(sessionStorage.getItem("userDetails"));

  const [previewedVideo, setPreviewedVideo] = useState("");

  let existedApplicationId = userData?.jobApplicationDetails.find(
    (value) => value.jobDetailsId === userData.jobDetailsId
  );

  let fileNameExisted = existedApplicationId?.introVidUrl.split("/");
  let fileNameTwoExisted = existedApplicationId?.assignmentVidUrl.split("/");

  const history = useHistory();
  let onLogout = useGoogleLogoutHook();
  const [fileName, setFileName] = useState(
    fileNameExisted ? fileNameExisted[fileNameExisted?.length - 1] ?? "" : ""
  );

  const [fileNameTwo, setFileNameTwo] = useState(
    fileNameTwoExisted
      ? fileNameTwoExisted[fileNameTwoExisted?.length - 1] ?? ""
      : ""
  );

  const [textareaValue, setTextAreaValue] = useState(
    existedApplicationId?.gitUrl ?? ""
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [tokenModalVisible, setTokenModalVisible] = useState(false);
  const [thankYouModalVisible, setThankYouModalVisible] = useState(false);
  const [errormessage, setErrorMessage] = useState("Its Error");
  const [visibileSucess, setVisibleSucess] = useState(true);
  const [isLinkValid, setIsLinkValid] = useState(
    /https:\/\/github.com\/[^\/]+\/[^\/]+/g.test(textareaValue)
  );

  const [finalStatus, setFinalStatus] = useState("");

  const [validVideo, setValidVideo] = useState({
    validOne: false,
    validTwo: false,
  });

  const onFileInputChange = (e) => {
    const file = e.target.files[0];
    let fileVideoName = e.target.name;

    if (file?.name?.split(".")[file?.name.split(".").length - 1] === "mp4") {
      setValidVideo({
        validOne: false,
        validTwo: false,
      });
      if (fileVideoName === "interview.mp4") {
        setFileName(file?.name);
      } else {
        setFileNameTwo(file?.name);
      }

      if (file?.name) {
        let fileObjReq = {
          video: file,
          jobApplicationId: existedApplicationId.jobApplicationId,
          videoName: fileVideoName,
        };

        let form_data = convertJsonToFormData(fileObjReq);

        props.updateIsLoading(true);
        axios
          .post("/shaft/api/job-applications/video/v1", form_data, {
            headers: { Authorization: userData?.jwtToken },
          })
          .then(function (resp) {
            if (resp.data.status === "success") {
              let userData = JSON.parse(sessionStorage.getItem("userDetails"));

              let updatedDetails = {
                ...userData,
                [fileVideoName === "interview.mp4"
                  ? "introVidUrl"
                  : "assignmentVidUrl"]: resp.data.response.responseUrl,
                videoName: file.name,
              };

              sessionStorage.setItem(
                "userDetails",
                JSON.stringify(updatedDetails)
              );

              props.updateIsLoading(false);
            } else {
              props.updateIsLoading(false);
              // setErrorMessage(
              //   "We are facing some technical issue, please again try after some time.(U-0016-B)"
              // );
              setErrorMessage({
                message:
                  "We are facing some technical issue, please again try after some time.",
                code: "[U-0016-B]",
              });
              setModalVisible(true);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              props.updateIsLoading(false);
              setModalVisible(true);
              // setErrorMessage("Session Timeout, Please login again.(U-0016-A)");
              setErrorMessage({
                message: "Session Timeout, Please login again.",
                code: "[U-0016-A]",
              });
              props.updateFormData(formDataObj);
              props.setloginFlag(false);
              onLogout();
            } else {
              props.updateIsLoading(false);
              setModalVisible(true);
              // setErrorMessage(
              //   "We are facing some technical issue, please again try after some time.(U-0016-A)"
              // );
              setErrorMessage({
                message:
                  "We are facing some technical issue, please again try after some time.",
                code: "[U-0016-A]",
              });
            }
          });
      }
    } else {
      if (fileVideoName === "interview.mp4") {
        setValidVideo((prev) => ({
          ...prev,
          validOne: true,
        }));
      } else {
        setValidVideo((prev) => ({
          ...prev,
          validTwo: true,
        }));
      }
    }
  };

  const validationCheck = () => {
    let gitRegex = /https:\/\/github.com\/[^\/]+\/[^\/]+/g;
    let validLink = new RegExp(
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    );
    let valid = false;
    if (existedApplicationId?.gitUrl) {
      if (
        textareaValue === "" ||
        !validLink.test(textareaValue) ||
        !gitRegex.test(textareaValue)
      ) {
        valid = true;
        setIsLinkValid(false);
      } else {
        setIsLinkValid(true);
      }
    }

    return valid;
  };

  const onSubmit = () => {
    let reviewApplication = {
      jobApplicationId: existedApplicationId?.jobApplicationId,
      gitUrl: textareaValue.trim(),
      introVidUrl: existedApplicationId?.introVidUrl,
      assignmentVidUrl: existedApplicationId?.assignmentVidUrl,
    };

    const form_data = convertJsonToFormData(reviewApplication);

    props.updateIsLoading(true);
    axios
      .post(
        "/shaft/api/job-applications/submit-job-application/v1",
        form_data,
        {
          headers: { Authorization: userData?.jwtToken },
        }
      )
      .then(function (resp) {
        if (resp.data.status === "success") {
          props.updateIsLoading(false);
          // const jobApplicationId = resp.data.response.jobApplicationId;

          // let updatedDetails = {
          //    ...userData,
          //    gitUrl: textareaValue,
          //    jobApplicationId: jobApplicationId,
          // };

          // sessionStorage.setItem("userDetails", JSON.stringify(updatedDetails));
          // history.push("/manage");

          if (resp.data.response.status === true) {
            setFinalStatus(resp.data.response.finalStatus);
            setThankYouModalVisible(true);
          } else {
            // setErrorMessage(
            //   "We are facing some technical issue, please again try after some time.(U-0013-B)"
            // );
            setErrorMessage({
              message:
                "We are facing some technical issue, please again try after some time.",
              code: "[U-0013-B]",
            });
            setModalVisible(true);
          }
        } else {
          props.updateIsLoading(false);
          // setErrorMessage(
          //   "We are facing some technical issue, please again try after some time.(U-0013-B)"
          // );
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0013-B]",
          });
          setModalVisible(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          props.updateIsLoading(false);
          setTokenModalVisible(true);
          // setErrorMessage("Session Timeout, Please login again.(U-0013-A)");
          setErrorMessage({
            message: "Session Timeout, Please login again.",
            code: "[U-0013-A]",
          });
          props.updateFormData(formDataObj);
          props.setloginFlag(false);
          onLogout();
        } else {
          props.updateIsLoading(false);
          setModalVisible(true);
          // setErrorMessage(
          //   "We are facing some technical issue, please again try after some time.(U-0013-A)"
          // );
          setErrorMessage({
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0013-A]",
          });
        }
      });
  };

  return (
    <div>
      <div className="main-container">
        {modalVisible ? (
          <ErrorModal
            errormessage={errormessage}
            close={() => {
              setModalVisible(false);
            }}
            visibile={modalVisible}
          />
        ) : null}
        {thankYouModalVisible ? (
          <ApplicationSuccessfulModal
            visibile={visibileSucess}
            close={() => {
              setVisibleSucess(false);
              history.push("/manage");
            }}
            finalStatus={finalStatus}
            applicationStatus={existedApplicationId}
          />
        ) : null}
        {tokenModalVisible ? (
          <ErrorModal
            errormessage={errormessage}
            close={() => {
              setTokenModalVisible(false);
              history.push("/");
            }}
            visibile={tokenModalVisible}
          />
        ) : null}
        {previewModalVisible ? (
          <Modal
            visibile={previewModalVisible}
            style={{ backgroundColor: "rgb(0 0 0 / 80%)" }}
          >
            <PopupTraningVideo
              // iframeSrc={existedApplicationId?.videoUrl}
              iframeSrc={previewedVideo}
              close={() => setPreviewModalVisible(false)}
            />
          </Modal>
        ) : null}

        <div className="pop-video-container">
          <div className="pv-git-upload-container">
            <div className="pv-git-container">
              <div className="pv-git-content">
                <div className="pv-details-container">
                  <div className="pv-span-container">
                    <span>
                      Application ID : {existedApplicationId?.jobApplicationId}
                      <strong> {}</strong>
                    </span>
                    <span>
                      <strong>
                        Designation : {existedApplicationId?.title}
                      </strong>
                    </span>
                  </div>
                  {existedApplicationId?.gitUrl && (
                    <h2 className="pv-update-git-header">
                      UPDATE YOUR GIT URL
                    </h2>
                  )}
                </div>

                {existedApplicationId?.gitUrl && (
                  <>
                    {isLinkValid ? (
                      <div className="pv-link-ok">
                        <span>Your link is valid</span>
                        <img
                          src={lightCheckmarkIcon}
                          alt="light-checkmark-icon"
                        />
                      </div>
                    ) : (
                      <div className="pv-link-ok">
                        <span>Your link is not valid</span>
                        <img
                          src={redCloseIcon}
                          alt="red-close-icon"
                          className="red-close-icon"
                        />
                      </div>
                    )}
                    <div className="pv-textarea-container">
                      <textarea
                        name="gitLink"
                        value={textareaValue}
                        onChange={(e) => {
                          setTextAreaValue(e.target.value);
                        }}
                      />
                      <div className="pv-git-icon-container">
                        <img
                          src={gitIcon}
                          alt="git-icon"
                          className="pv-git-icon"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="both-video-container">
              {/* {existedApplicationId?.introVidUrl ? ( */}
              {existedApplicationId?.introVidUrl ? (
                <div className="individual-video-content-container">
                  <div
                    className="pv-record-icon-container"
                    onClick={() => {
                      setPreviewedVideo(existedApplicationId?.introVidUrl);
                      setPreviewModalVisible(true);
                    }}
                  >
                    {/* <img
                      // src={selfThumbnail}
                      className="image-thumbnail"
                      alt="thumbnail"
                    /> */}
                    <div className="pv-youtube-icon">
                      <img
                        src={playButton}
                        className="pv-youtube-play-button"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="pv-upload-video-container">
                    <h2>UPDATE YOUR VIDEO FILE</h2>
                    {/* <p>
                      Luctus molestie maecenas arcu neque quis vulputate at.
                      Orci tellus consequat, posuere{" "}
                    </p> */}
                    <div className="pv-uploadbtn-checkmark-container">
                      <div className="pv-upload-btn-container">
                        <Button
                          style={{
                            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 25px 0px",
                            color: "#fff",
                            backgroundColor: "var(--secondaryColor)",
                            position: "relative",
                            border: "2px solid white",
                            height: "35px",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="pv-upload-btn-cotainer">
                            <input
                              type="file"
                              onChange={onFileInputChange}
                              style={{ position: "absolute" }}
                              name="interview.mp4"
                              id="video"
                              title=""
                              accept="video/mp4,video/x-m4v,video/*"
                            />
                            <span>Update video</span>
                            <img src={uploadIcon} alt="upload-icon" />
                          </div>
                        </Button>
                        {fileName ? <span>{fileName}</span> : null}
                      </div>
                      {validVideo.validOne ? (
                        <div className="pv-video-not-ok-container">
                          <span>You video is not ok</span>
                          <img
                            src={redCloseIcon}
                            alt="light-checkmark-icon"
                            className="red-close-icon"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pv-upload-video-container"></div>
              )}
              {/* {existedApplicationId?.assignmentVidUrl ? ( */}
              {existedApplicationId?.assignmentVidUrl ? (
                <div className="individual-video-content-container">
                  <div
                    className="pv-record-icon-container assignment-image"
                    onClick={() => {
                      setPreviewedVideo(existedApplicationId?.assignmentVidUrl);
                      setPreviewModalVisible(true);
                    }}
                  >
                    {/* <img
                      src={assThumbnail}
                      className="image-thumbnail"
                      alt="thumbnail"
                    /> */}
                    <div className="pv-youtube-icon">
                      <img
                        src={playButton}
                        className="pv-youtube-play-button"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="pv-upload-video-container">
                    <h2>UPDATE YOUR ASSIGNMENT VIDEO FILE</h2>
                    {/* <p>
                      Luctus molestie maecenas arcu neque quis vulputate at.
                      Orci tellus consequat, posuere{" "}
                    </p> */}
                    <div className="pv-uploadbtn-checkmark-container">
                      <div className="pv-upload-btn-container">
                        <Button
                          style={{
                            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 25px 0px",
                            color: "#fff",
                            backgroundColor: "var(--secondaryColor)",
                            position: "relative",
                            border: "2px solid white",
                            height: "35px",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="pv-upload-btn-cotainer">
                            <input
                              type="file"
                              onChange={onFileInputChange}
                              style={{ position: "absolute" }}
                              name="assignment.mp4"
                              id="video"
                              title=""
                              accept="video/mp4,video/x-m4v,video/*"
                            />
                            <span>Update video</span>
                            <img src={uploadIcon} alt="upload-icon" />
                          </div>
                        </Button>
                        {fileNameTwo ? <span>{fileNameTwo}</span> : null}
                      </div>
                      {validVideo.validTwo ? (
                        <div className="pv-video-not-ok-container">
                          <span>You video is not ok</span>
                          <img
                            src={redCloseIcon}
                            alt="light-checkmark-icon"
                            className="red-close-icon"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pv-upload-video-container"></div>
              )}
            </div>
          </div>
          <div className="pv-complete-btn-container">
            <div
              className="pv-complete-btn"
              style={{
                background:
                  existedApplicationId?.status?.toLowerCase() === "pending"
                    ? "var(--primaryColor)"
                    : "#dc9b98",
              }}
              onClick={() => {
                if (existedApplicationId?.status?.toLowerCase() === "pending") {
                  if (!validationCheck()) {
                    onSubmit();
                  }
                }
              }}
            >
              {`Submit`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.formData.userId,
  jwtToken: state.jwtToken,
  isLoading: state.isLoading,
});
export default connect(mapStateToProps, {
  updateIsLoading,
  updateFormData,
  setloginFlag,
})(PopVideo);
