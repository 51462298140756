import { JWT_TOKEN } from "../actions/jwtToken-action";

const INITIAL_STATE = null;

const jwtTokenReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case JWT_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

export default jwtTokenReducer;
