import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useGoogleLogout } from "react-google-login";
import { useCallback } from "react";

import MobileNavigation from "../MobileNavigation";
import RefHeaderPopup from "../RefHeaderPopup";
import Button from "../Button";
import GoogleSignIn from "../GoogleSignIn";
import Notification from "../Notification";
import { setloginFlag } from "../../redux/actions/login-flag-action";
import {
  updateFormData,
  formDataObj,
} from "../../redux/actions/form-data-action";
import { getSession } from "../../utilites/SessionFns";
import ErrorModal from "../ErrorModal";
import RefHeader from "../RefHeader/RefHeader";

// import logoImg from "../../assets/images/HireStarLogo3.svg";
import logoImg from "../../assets/images/hirestarNewLogo.png";
import menuIcon from "../../assets/images/menu-icon.svg";
import GoogleLogoIcon from "../../assets/images/google-logo-icon.svg";

import "./header-style.css";

const Header = ({
  refferalName,
  refferalDetails,
  loginFlag,
  userId,
  setloginFlag,
  updateFormData,
}) => {
  const [mobileNav, setMobileNav] = useState(false);
  const [signInDropdown, setSignInDropdown] = useState(false);
  const [errormessage, setErrorMessage] = useState("Its Error");
  const [headerPopup, setHeaderPopup] = useState(false);
  const userData = getSession("userDetails");

  const dropdownContainerRef = useRef();
  const history = useHistory();

  const location = useLocation();

  const [modalVisible, setModalVisible] = useState(false);

  // const clientId =
  //   "571067888966-jcbdnvmr8ufe6u5vcbamrqbctjgdgrua.apps.googleusercontent.com";

  const clientId =
    "637837369163-f2d0b04nlfo7smtfkuts9qa5v4dh7sum.apps.googleusercontent.com";
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess: (res) => console.log("Sucess", res),
    onFailure: (res) => console.log("Failure", res),
  });
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setHeaderPopup(false);
    }
  }, []);

  useEffect(() => {
    const onBodyClick = (e) => {
      if (!dropdownContainerRef?.current?.contains(e.target)) {
        setSignInDropdown(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", escFunction);
    return () => {
      document.body.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  const onLogoutClick = () => {
    updateFormData(formDataObj);
    setloginFlag(false);
    sessionStorage.clear();
    signOut();
    history.push("/");
  };

  const showErrorModal = (code = "") => {
    setErrorMessage({
      message: "Error Occured while Login in Please try again.",
      code: code,
    });
    setModalVisible(true);
    onLogoutClick();
  };

  return (
    <div>
      {modalVisible ? (
        <ErrorModal
          errormessage={errormessage}
          close={() => setModalVisible(false)}
          visibile={modalVisible}
        />
      ) : null}
      {setHeaderPopup ? (
        <RefHeaderPopup
          visibile={headerPopup}
          close={() => {
            setHeaderPopup(false);
          }}
        ></RefHeaderPopup>
      ) : null}
      <div className="main-container">
        <div className="header-container">
          <div className="header-left-container">
            {refferalDetails?.logo !== "" &&
            refferalDetails?.logo !== null &&
            refferalDetails?.logo !== undefined ? (
              <RefHeader
                refferalDetails={refferalDetails}
                refferalName={refferalName}
              ></RefHeader>
            ) : (
              <div
                className="logo-img-container"
                onClick={() => {
                  if (!location.pathname.includes("login")) {
                    history.push("/");
                  }
                }}
              >
                <img
                  className="logo logo-new"
                  src={logoImg}
                  alt="tenko point"
                />
              </div>
            )}
          </div>

          <div className="header-right-container">
            {userId ? <Notification /> : null}

            <div
              className="header-user-info-container"
              ref={dropdownContainerRef}
              onClick={() => setSignInDropdown(!signInDropdown)}
            >
              <div className="header-user-icon-container">
                <img
                  style={{
                    borderRadius: loginFlag ? "50%" : 0,
                    padding: loginFlag ? "4px" : "7px",
                  }}
                  src={
                    loginFlag ? userData?.formData?.profilePic : GoogleLogoIcon
                  }
                  alt="user"
                />
              </div>

              <div
                className={`header-user-dropdown-container ${
                  signInDropdown ? "header-user-dropdown-active" : ""
                }`}
              >
                {loginFlag ? (
                  <>
                    <div className="header-user-profile-container">
                      <img src={userData?.formData?.profilePic} alt="profile" />
                      <div className="header-user-profile-headings">
                        <h4>{userData?.formData?.name}</h4>
                        <p>{userData?.formData?.email}</p>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "var(--primaryColor)",
                          border: "solid 2px var(--primaryColor)",
                          marginTop: "10px",
                        }}
                        onClick={onLogoutClick}
                      >
                        Sign Out
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p>Please sign in to apply for Job</p>
                    <GoogleSignIn
                      showPopup={showErrorModal}
                      loginSuccess={() => {}}
                      userNotSignIn={(data) => {}}
                    />
                  </>
                )}
              </div>
            </div>
            <div
              className="menu-container"
              onClick={() => {
                document.body.style.overflow = "hidden";
                setMobileNav(!mobileNav);
              }}
            >
              <img src={menuIcon} alt="menu" />
            </div>
          </div>
        </div>
      </div>
      <MobileNavigation
        showMobileNav={mobileNav}
        refferalDetails={refferalDetails}
        refferalName={refferalName}
        onDismiss={() => {
          document.body.style.overflow = "auto";
          setMobileNav(false);
        }}
        mobileNavFooter={() =>
          loginFlag ? (
            <div>
              <div className="header-user-info-container">
                <div
                  style={{ overflow: "hidden" }}
                  className="header-user-icon-container"
                >
                  <img src={userData?.formData?.profilePic} alt="user" />
                </div>
                <span>{loginFlag ? userData?.formData?.name : null}</span>
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: "var(--primaryColor)",
                    marginTop: "10px",
                  }}
                  onClick={onLogoutClick}
                >
                  LOG OUT
                </Button>
              </div>
            </div>
          ) : null
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginFlag: state?.loginFlag,
  userId: state?.formData?.userId,
});

export default connect(mapStateToProps, {
  setloginFlag,
  updateFormData,
})(Header);
