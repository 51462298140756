import React, { useState, useEffect } from "react";

import "./shape-style.css";

const Shape = ({ shapeTitle, index, title }) => {
  const isUndefined = index === undefined;

  const [titleSize, setTitleSize] = useState("");

  

  useEffect(() => {

    if (title?.length < 3) {
      setTitleSize("sp-small");
    } else if (title?.length > 3 && title?.length < 10) {
      setTitleSize("sp-medium");
    } else {
      setTitleSize("sp-big");
    }
  }, [title]);

  return (
    <div
      className={isUndefined ? "default-bg" : index % 2 === 0 ? "bg" : "sec-bg"}
    >
      <div
        className={
          isUndefined
            ? "default-cricle"
            : index % 2 === 0
            ? "cricle"
            : "sec-cricle"
        }
      />
      <div
        className={
          isUndefined
            ? "default-square"
            : index % 2 === 0
            ? "square"
            : "sec-square"
        }
      >
        <div className="sp-title-container">
          <p className={`shape-title ${titleSize}`}>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default Shape;
