
function convertJsonToFormData(jsonObj) {
  const form_data = new FormData();

  for (const key in jsonObj) {
    form_data.append(key,jsonObj[key]);
  }

  return form_data;
  
}

export default convertJsonToFormData;
