import { IS_LOADING } from "../actions/isLoading-action";

const INITIAL_STATE = false;

const isLoadingReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case IS_LOADING:
         return action.payload
      default:
         return state;
   }
}

export default isLoadingReducer;