import React from "react";

import { connect } from "react-redux";

import "./why-teknopoint-section-style.css";

const WhyTeknoPointSection = ({ refferalName, refferalDetails }) => {

  return (
    <div>
      <div className="wtp-bg-img-container">
        <div className="main-container">
          {refferalDetails || refferalName?.includes("@") ? (
            <div className="wtp-referral-container">
              <h2>About Referral</h2>
              <div className="referral-details-container">
                {refferalDetails?.logo && (
                  <div className="ref-image-container">
                    <img src={refferalDetails?.logo} className="ref-logo" alt="ref"></img>
                  </div>
                )}

                <div className="referral-details">
                  {refferalDetails ? (
                    <p>
                      <span className="referral-bold">Name:</span>{" "}
                      <span>{refferalDetails?.refferalName}</span>
                    </p>
                  ) : null}

                  {refferalDetails ? (
                    <p className="email-container">
                      <span className="referral-bold">Email:</span>{" "}
                      <span>{refferalDetails?.emailId}</span>
                    </p>
                  ) : (
                    <p>
                      <span className="referral-bold">Email:</span>{" "}
                      <span>{refferalName}</span>
                    </p>
                  )}

                  {refferalDetails ? (
                    <p>
                      <span className="referral-bold">Mobile No:</span>{" "}
                      <span>{refferalDetails?.mobileNo}</span>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="wtp-container">
              <h2>Why Tekno Point?</h2>
              <div className="wtp-info">
                <hr />
                <div>
                  <p style={{ marginBottom: "10px" }}>
                    We're steering the future of Digital Transformation. We're
                    in business with the leading companies of the world to
                    elevate their customer experience journey. We're looking for
                    talent who is fearless and can bring creativity to life.
                  </p>
                  <p>
                    Whether you are a college graduate or a professional with
                    experience, join our diverse team to help leading companies
                    achieve their business goals. We're committed to helping you
                    reach your point of full potential. Are you ready to
                    explore?
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ loginFlag: state.loginFlag });

export default connect(mapStateToProps)(WhyTeknoPointSection);
