import React from "react";

//styles
import "./simple-loader-styles.css";

//Images
import LoaderIcon from "../../assets/images/loaderIcon.png";

//Components
import Modal from "../Modal";

const SimpleLoader = () => {
  return (
    <Modal visibile={true} style={{ backgroundColor: "rgb(0 0 0 / 60%)" }}>
      <div className="loader-container">
        <img src={LoaderIcon} alt="loading-icon" className="loading-img" />
        <p>LOADING...</p>
      </div>
    </Modal>
  );
};

export default SimpleLoader;
