import React, { useRef } from "react";

import videoIcon from "../../assets/images/video-icon.svg";
import SelectOpeningsIcon from "../../assets/images/SelectOpeningsIcon.svg";
import assignmentIcon from "../../assets/images/assignment-icon.svg";
import gitIcon from "../../assets/images/git-icon-copy.svg";
import recordIcon from "../../assets/images/record-icon.svg";
import registerIcon from "../../assets/images/regiIcon.svg";

import "./interview-process-info-graphics.css";
import { connect } from "react-redux";

const InterviewProcessInfoGraphics = ({
  homeDisplay,
  steps = "4 steps to your dream job!",
  continueRef,
  userId,
}) => {
  const messageLists = useRef();

  return (
    <div className={homeDisplay ? "main-container" : ""}>
      <div
        className={`interview-process-steps-container ${
          homeDisplay ? "interview-process-info-graphics-home-container" : ""
        }`}
        ref={continueRef}
        style={{ borderRadius: homeDisplay ? "10px" : "" }}
      >
        <h2>
          {!homeDisplay && userId
            ? "4 steps to your dream job!"
            : steps}
        </h2>
        <ul ref={messageLists}>
          {!userId ? (
            <li className="process-step">
              <div className="process-step-icon-container">
                <img src={registerIcon} alt="register-icon" />
              </div>
              <div className="process-step-message-container">
                {/* <p>2.</p> */}
                <p>Register using your Gmail</p>
              </div>
            </li>
          ) : null}

          {homeDisplay ? (
            <li className="process-step">
              <div className="process-step-icon-container">
                <img src={SelectOpeningsIcon} alt="video-icon" />
              </div>
              <div className="process-step-message-container">
                {/* <p>1.</p> */}
                <p>Select job from the listing</p>
              </div>
            </li>
          ) : null}
          <li className="process-step">
            <div className="process-step-icon-container">
              <img src={videoIcon} alt="video-icon" />
            </div>
            <div className="process-step-message-container">
              {/* <p>2.</p> */}
              <p>Go-through the Training Videos</p>
            </div>
          </li>
          <li className="process-step">
            <div className="process-step-icon-container">
              <img src={assignmentIcon} alt="assignment-icon" />
            </div>
            <div className="process-step-message-container">
              {/* <p>3.</p> */}
              <p>Complete the assignment</p>
            </div>
          </li>
          <li className="process-step">
            <div className="process-step-icon-container">
              <img src={gitIcon} alt="git-icon" />
            </div>
            <div className="process-step-message-container">
              {/* <p>4.</p> */}
              <p>Submit assignment via GitHub</p>
            </div>
          </li>
          <li className="process-step">
            <div className="process-step-icon-container">
              <img src={recordIcon} alt="record-icon" />
            </div>
            <div className="process-step-message-container">
              {/* <p>5.</p> */}
              <p>Submit your explanatory video</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.formData.userId,
});

export default connect(mapStateToProps)(InterviewProcessInfoGraphics);
