import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

import { updateIsLoading } from "../../redux/actions/isLoading-action";
import { setloginFlag } from "../../redux/actions/login-flag-action";
import {
  updateFormData,
  formDataObj,
} from "../../redux/actions/form-data-action";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";
import ErrorModal from "../ErrorModal";
import starIcon from "../../assets/images/starIconApp.svg";
import userIcon from "../../assets/images/userIconApp.svg";
import Shape from "../Shape/Shape";
import "./developer-cateory-style.css";

const DeveloperCateory = ({
  title,
  corelanguages,
  shapeTitle,
  index,
  category,
  updateIsLoading,
  setloginFlag,
  updateFormData,
  userId,
}) => {
  //Logs

  let userData = JSON.parse(sessionStorage.getItem("userDetails"));

  const [modalVisible, setModalVisible] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [jobExceeded, setJobsExceeded] = useState(false);
  const onLogout = useGoogleLogoutHook();
  const history = useHistory();

  const isEligible = () => {
    console.log(category.id);

    window.dataLayer.push({
      event: "jobcardclick",
      data: {
        jobTitle: `${category?.title?.replaceAll(" ", "-")}-${category?.id}`,
      },
    });
    if (userId) {
      let appliedJobApplication = userData?.jobApplicationDetails
        ? userData?.jobApplicationDetails.filter(
            (obj) => obj.jobDetailsId === category.id
          ).length > 0
        : false;

      let status = userData?.jobApplicationDetails?.find(
        (obj) => obj.status.toLowerCase() === "hired"
      );
      console.log("my details", userData?.jobApplicationDetails);

      if (appliedJobApplication || status) {
        let updatedDetails = {
          ...userData,
          jobDetailsId: category.id,
        };
        sessionStorage.setItem("userDetails", JSON.stringify(updatedDetails));

        if (status?.jobDetailsId == category.id) {
          history.push({
            pathname: `/interview-process/${category.title
              .toLowerCase()
              .replaceAll(" ", "-")}`,
            state: "yes",
          });
        } else {
          if (status) {
            setErrorMessage({
              message: `You have already been hired for ${status.jobApplicationId}`,
              code: "[U-0010-A]",
            });
            setModalVisible(true);
          } else {
            history.push({
              pathname: `/interview-process/${category.title
                .toLowerCase()
                .replaceAll(" ", "-")}`,
              state: "yes",
            });
          }
        }
      } else {
        const form_data = new FormData();
        form_data.append("userId", userId);

        updateIsLoading(true);
        axios
          .post("/shaft/api/user/is-eligible-for-job/v1", form_data, {
            headers: { Authorization: userData?.jwtToken },
          })
          .then((resp) => {
            updateIsLoading(false);
            if (resp.data.status === "success") {
              if (resp.data.response.isEligible === "yes") {
                let updatedDetails = {
                  ...userData,
                  jobDetailsId: category.id,
                };

                sessionStorage.setItem(
                  "userDetails",
                  JSON.stringify(updatedDetails)
                );
                history.push({
                  pathname: `/interview-process/${category.title
                    .toLowerCase()
                    .replaceAll(" ", "-")}`,
                  state: "yes",
                });
              } else {
                updateIsLoading(false);
                setErrorMessage({
                  message: "You have already applied for 2 positions",
                  code: "[U-0010-A]",
                });
                setModalVisible(true);
              }
            } else {
              updateIsLoading(false);
              setErrorMessage({
                message: "You have already applied for 2 positions",
                code: "[U-0010-A]",
              });
              setModalVisible(true);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              updateIsLoading(false);
              setModalVisible(true);
              setErrorMessage({
                message: "Session Timeout, Please login again.",
                code: "[U-0010-A]",
              });
              updateFormData(formDataObj);
              setloginFlag(false);
              onLogout();
            } else {
              updateIsLoading(false);
              setModalVisible(true);
              setErrorMessage({
                message:
                  "We are facing some technical issue, please again try after some time.",
                code: "[U-0010-A]",
              });
            }
          });
      }
    } else {
      let userData = JSON.parse(sessionStorage.getItem("userDetails"));

      let updatedDetails = {
        ...userData,
        jobDetailsId: category.id,
      };
      sessionStorage.setItem("userDetails", JSON.stringify(updatedDetails));
      history.push({
        pathname: `/interview-process/${category.title
          .toLowerCase()
          .replaceAll(" ", "-")}`,
        state: "yes",
      });
    }
  };

  return (
    <>
      {modalVisible ? (
        <ErrorModal
          errormessage={errormessage}
          title={errormessage?.message?.includes("applied") ? "" : "Oh snap!"}
          close={() => {
            setModalVisible(false);
          }}
          visibile={modalVisible}
        />
      ) : null}
      <div className="dc-container" onClick={isEligible}>
        <p
          className={`GA-${category.title.replaceAll(" ", "-")}-${category.id}`}
          style={{ display: "none" }}
        >{`${category.title}-${category.id}`}</p>
        <Shape
          shapeTitle={shapeTitle}
          index={index}
          title={category.shortTitle}
        />
        <div className="dc-content">
          {category.userJobCount > 0 ? (
            <div className="applied-text">
              <img src={userIcon} />
              <p className="applied-font">Applied-{category.userJobCount}</p>
            </div>
          ) : (
            <div className="applied-text">
              <img src={starIcon} />
              <p className="applied-font">New</p>
            </div>
          )}
          <p
            className={`GA-${category.title.replaceAll(" ", "-")}-${
              category.id
            }`}
            style={{ display: "none" }}
          >{`${category.title}-${category.id}`}</p>
          <h3>{title}</h3>
          <p
            className="inheritLi"
            dangerouslySetInnerHTML={{ __html: corelanguages }}
          />
          <p style={{ color: "var(--primaryColor)" }}>Read more...</p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  jwtToken: state.jwtToken,
  loginFlag: state.loginFlag,
  userId: state.formData.userId,
});
export default connect(mapStateToProps, {
  updateIsLoading,
  updateFormData,
  setloginFlag,
})(DeveloperCateory);
