import React from "react";
import { useHistory } from "react-router-dom";
//styles
import "./page-not-found-style.css";
import Button from "../Button";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <div className="main-container">
      <div className="pageNotFound-container">
        <h5>404</h5>
        <p>Page not found</p>
        <span>
          The page you are looking for has been removed or temporarily
          unavailable.
        </span>
        <Button
          onClick={() => history.push('/')}
          style={{ maxWidth: "200px", background: "var(--primaryColor)" }}
        >
          Go To Home Page
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
