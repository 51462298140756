import "./LoginPopup-style.css";
import Modal from "../Modal";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";
// import { getSession } from "../../utilites/SessionFns";
import GoogleSignIn from "../GoogleSignIn";

const LoginPopup = ({
  visibile,
  modelError,
  loginStatus,
  setLoginPopup,
  googleBtnText = "Sign up",
  LoginPopUpTxt = "Seems like you are already Signed up! Sign-in Instead",
}) => {
  const onLogout = useGoogleLogoutHook();

  const showErrorModal = () => {
    modelError();
    onLogout();
  };

  return (
    <Modal visibile={visibile} style={{ backgroundColor: "rgb(0 0 0 / 80%)" }}>
      <div className="frame">
        <div className="modal" style={{ padding: "10px" }}>
          <span className="title" style={{ fontWeight: "bold" }}>
            {LoginPopUpTxt}
          </span>
          <GoogleSignIn
            showPopup={showErrorModal}
            loginSuccess={(data) => {
              loginStatus(data);
            }}
            googleBtnText={googleBtnText}
            userNotSignIn={(data) => {}}
          />
        </div>
      </div>
    </Modal>
  );
};
export default LoginPopup;
