
const FORM_DATA = 'FORM_DATA';

const formDataObj = {
   userId: null,
   name: '',
   email: '',
   city: '',
   degree: '',
   yearOfPassing: '',
   experience: '',
   resumeFile: { name: '', base64: '' },
   linkedinProfile: '',
}

const updateFormData = (value) => ({ type: FORM_DATA, payload: value })

export { FORM_DATA, formDataObj, updateFormData };