
const getSession = (key) =>  {
   let data = sessionStorage.getItem(key);
   if(data){
      data = JSON.parse(data);
   }
   return data;
}

const setSession = (value) => {
   sessionStorage.setItem("userDetails", JSON.stringify(value));
}

export { getSession, setSession };