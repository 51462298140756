import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import the GTM Module so you can access it
import TagManager from "react-gtm-module";

import store from "./redux";
import App from "./App";

import "./index.css";
import { HashRouter } from "react-router-dom";

//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production'
// const tagManagerArgs = {
//   gtmId: "GTM-TWFB4H9",
//   //the below changes GTM values based on whether dev or production
//   auth: env === "development" ? "<dev ga_auth>" : "<live ga_auth>",
//   preview: env === "development" ? "<dev ga_env>" : "<live ga_env>",
// };
// import reportWebVitals from './reportWebVitals';
// import "bootstrap/dist/css/bootstrap.min.css";
const tagManagerArgs = {
  gtmId: "GTM-TWFB4H9",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
