import ReactDOM from "react-dom";

import "./modal-style.css";

const Modal = ({ visibile = false, children, style, ...otherProps }) => {
  return ReactDOM.createPortal(
    <div
      className="modal-container"
      style={{ ...style, display: visibile ? "flex" : "none" }}
      {...otherProps}
    >
      {children}
    </div>,
    document.querySelector("#root-modal")
  );
};

export default Modal;
