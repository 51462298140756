import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: "pageview",
      data: {
        pagePath: window.location.href,
      },
    });

  }, [pathname]);

  return null;
};

export default ScrollToTop;
