import { useHistory, useLocation } from "react-router-dom";

import logoImg from "../../assets/images/HireStarLogo3.svg";
import "./footer-style.css";

const Footer = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="footer-container">
      <div className="main-container">
        <div className="footer-navigation-container">
          <div
            className="footer-logo-container"
            onClick={() => {
              if (!location.pathname.includes("login")) {
                history.push("/");
              }
            }}
          >
            <p style={{ fontSize: "14px", textAlign: "center" }}>Powered By</p>
            <img className="logo" src={logoImg} alt="tenko point" />
          </div>
          <nav className="footer-navigation-links">
            <ul>
              <li>
                <a
                  href="https://teknopoint.in/company/about-us/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://teknopoint.in/contact/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="footer-additional-container">
        <div className="main-container">
          <div className="footer-additional-content">
            <ul>
              <li>&copy; Tekno Point {new Date().getFullYear()}</li>
              <li className="privary-policy-link">
                <a
                  href="https://teknopoint.in/terms-of-use/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  Terms of Use |
                </a>
                <a
                  href="https://teknopoint.in/privacy/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  Privacy Policy | Disclaimer
                </a>
              </li>
              <li>Design by Tekno Point</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
