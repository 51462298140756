import React from "react";
import Modal from "../Modal";
import closeCircleIcon from "../../assets/images/closeCircleIcon.svg";
import "./RefHeaderPopup-style.css";
const RefHeaderPopup = ({ visibile, close }) => {
  return (
    <div>
      <Modal
        visibile={visibile}
        style={{ backgroundColor: "rgb(0 0 0 / 80%)" }}
      >
        <div className="header-popup-cont">
          <img
            src={closeCircleIcon}
            className="refclose-icon"
            onClick={close}
            alt="close"
          ></img>
          <div className="tekno-content">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. <br /> Neque porro
            quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
            adipisci velit, sed quia non numquam eius modi tempora incidunt ut
            labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad
            minima veniam, quis nostrum exercitationem ullam corporis suscipit
            laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
            vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
            molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?" Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sunt incidunt alias quasi beatae tenetur aut magni ea
            consequuntur in, dicta explicabo quisquam a qui repudiandae, ullam,
            doloremque quis quam quo! Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Laudantium, blanditiis! Sunt, quis. Praesentium
            dolorum, itaque sunt dolor at quos beatae perferendis quis officia
            porro molestias impedit incidunt tempora delectus molestiae,
            voluptatem eveniet quasi, soluta explicabo enim omnis tenetur nobis
            minus. Assumenda tenetur quos, doloribus placeat illo quidem. Culpa,
            quas accusantium?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Rem cupiditate eos illum ratione harum quia a nemo earum illo
            soluta quibusdam cum magnam tenetur sed, dolor iusto praesentium
            tempora sapiente. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Possimus fugiat dolorem provident voluptatibus voluptatum
            voluptatem labore nesciunt tempora reiciendis sapiente, ea eum
            similique doloremque corporis aliquam ex in sunt ut. Lorem ipsum
            dolor sit amet consectetur adipisicing elit. Nobis facilis tenetur
            iusto porro. Doloremque sit culpa, explicabo obcaecati dolore ullam
            quae cumque praesentium laudantium. Aperiam ullam rem quos quia
            dolores. Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Alias, dolorem cum amet porro eligendi id atque excepturi possimus
            iste deleniti deserunt eaque, voluptas consectetur aperiam nisi et,
            magni minima quisquam! Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Quaerat beatae dicta numquam eveniet suscipit
            voluptate debitis exercitationem, molestiae, reiciendis ab commodi
            similique error corrupti ipsa cum facilis distinctio! Rem, quod.
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto
            expedita odit eligendi eos fuga, delectus nihil tenetur officiis
            molestiae at temporibus quasi laboriosam illo asperiores alias
            debitis atque, accusamus culpa. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Quos, veritatis.<br></br> Qui et illo
            aperiam iste, vero odio, aut quisquam ducimus laboriosam magnam
            praesentium dolor. Facere alias enim ea in qui. Lorem ipsum dolor
            sit amet consectetur adipisicing elit. Aperiam natus vel sed,
            corporis et nemo, corrupti perspiciatis dolores laborum
            reprehenderit, cupiditate quibusdam! Est ducimus deleniti ipsa alias
            sed cumque placeat? Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Quo et natus laudantium dolorem facilis in
            consequuntur voluptate atque! Alias debitis provident explicabo
            voluptatum assumenda quaerat optio eos adipisci magni hic? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Cumque, nulla id
            quaerat consectetur nobis porro nemo hic doloremque non eaque
            accusantium illo laboriosam, vitae cum commodi pariatur provident
            sed praesentium!
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default RefHeaderPopup;
