import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import Button from "../Button";
import { getSession, setSession } from "../../utilites/SessionFns";
import { setloginFlag } from "../../redux/actions/login-flag-action";
import convertJsonToFormData from "../../utilites/convertJsonToFormData";
import { updateJwtToken } from "../../redux/actions/jwtToken-action";
import {
  updateFormData,
  formDataObj,
} from "../../redux/actions/form-data-action";
import { updateIsLoading } from "../../redux/actions/isLoading-action";
import ErrorModal from "../ErrorModal";
import useGoogleLogoutHook from "../../custom-hooks/useGoogleLogoutHook";
import GoogleLogoIcon from "../../assets/images/google-logo-icon.svg";
import "./google-signin-style.css";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import deployment from "../../config/enviroment";
import { setCookie } from "../../utilites/cookieStore";
import { CustomGoogleLoginButton } from "../CustomGoogleLoginButton/CustomGoogleLoginButton";
const GoogleSignIn = ({
  setloginFlag,
  updateJwtToken,
  updateFormData,
  updateIsLoading,
  btnClassName = false,
  icon = false,
  googleBtnText = "Sign in with Google",
  loginSuccess,
  userNotSignIn,
  waitForIt = () => {},
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [errormessage, setErrorMessage] = useState("Its Error");
  const [validDomain, setValidDomain] = useState(false);
  const [autoLoad, setAutoLoad] = useState(location.pathname.includes("login"));
  let initialUserDetails = getSession("userDetails");
  let onLogout = useGoogleLogoutHook();
  const hirestarclientId =
    "637837369163-f2d0b04nlfo7smtfkuts9qa5v4dh7sum.apps.googleusercontent.com";
  // const clientId =
  //   "571067888966-jcbdnvmr8ufe6u5vcbamrqbctjgdgrua.apps.googleusercontent.com";
  let count = 0;
  const onSuccess = (response) => {
    let res = {
      profileObj: {
        ...response.data,
        imageUrl: response.data.picture,
        googleId: response.data.id,
      },
      response: response,
    };
    setAutoLoad(false);
    if (location?.pathname?.includes("login")) {
      waitForIt(res);
    } else {
      let data = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        profilePic: res.profileObj.imageUrl,
        googleId: res.profileObj.googleId,
      };
      const form_data = convertJsonToFormData(data);
      updateIsLoading(true);
      axios
        .post("/shaft/api/user/login/v1", form_data)
        .then((resp) => {
          updateIsLoading(false);
          let userDetails = {
            ...initialUserDetails,
            formData: {
              email: res.profileObj.email,
              name: res.profileObj.name,
              profilePic: res.profileObj.imageUrl,
            },
            loginFlag: true,
            jwtToken: resp.data.response.tokenDetails.jwtToken,
          };
          setSession(userDetails);
          if (resp.data.status === "success") {
            setloginFlag(true);
            if (resp.data.response.userExists) {
              updateJwtToken(resp.data.response.tokenDetails.jwtToken);
              let userData = getSession("userDetails");
              setSession({
                ...userData,
                jwtToken: resp.data.response.tokenDetails.jwtToken,
              });
              if (googleBtnText.toLowerCase() === "sign up with google") {
                userNotSignIn(true);
              } else {
                const emailFormData = new FormData();
                emailFormData.append("email", res.profileObj.email);
                updateIsLoading(true);
                axios
                  .post(
                    "/shaft/api/user/user-details-based-on-email-Id/v1",
                    emailFormData,
                    {
                      headers: {
                        Authorization: resp.data.response.tokenDetails.jwtToken,
                      },
                    }
                  )
                  .then((userObj) => {
                    updateIsLoading(false);
                    if (userObj.data.status === "success") {
                      let setUserInfo = getSession("userDetails");
                      setSession({
                        ...setUserInfo,
                        jobApplicationDetails:
                          userObj.data.response.jobApplicationDetails,
                        formData: {
                          ...userObj.data.response.userDetails,
                          profilePic: res.profileObj.imageUrl,
                        },
                      });
                      updateFormData(userObj.data.response.userDetails);
                      loginSuccess(true);
                      // history.push("/");
                    } else {
                      setModalVisible(true);
                      setErrorMessage({
                        message:
                          "Something went wrong,our team is working on it. Please try again.",
                        code: "[U-0002-B]",
                      });
                    }
                  })
                  .catch((err) => {
                    if (err.response.status === 401) {
                      updateIsLoading(false);
                      setModalVisible(true);
                      setErrorMessage({
                        message: "Session Timeout, Please login again.",
                        code: "[U-0002-A]",
                      });
                      updateFormData(formDataObj);
                      setloginFlag(false);
                      onLogout();
                    } else {
                      updateIsLoading(false);
                      setModalVisible(true);
                      setErrorMessage({
                        message:
                          "Something went wrong,our team is working on it. Please try again.",
                        code: "[U-0002-A]",
                      });
                      loginSuccess(false);
                    }
                  });
              }
            } else {
              updateIsLoading(false);
              loginSuccess(false);
              history.push("/sign-up");
            }
          } else {
            updateIsLoading(false);
            setModalVisible(true);
            setErrorMessage({
              message:
                "We are facing some technical issue, please again try after some time.",
              code: "[U-0001-B]",
            });
            // loginSuccess(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            updateIsLoading(false);
            setModalVisible(true);
            setErrorMessage({
              message: "Session Timeout, Please login again.",
              code: "[U-0001-A]",
            });
            updateFormData(formDataObj);
            setloginFlag(false);
            onLogout();
          } else {
            updateIsLoading(false);
            setModalVisible(true);
            // setErrorMessage({
            //   message:
            //     "We are facing some technical issue, please again try after some time.",
            //   code: "[U-0001-A]",
            // });
            setErrorMessage({
              message: JSON.stringify(error.response.data),
              code: "[U-0001-A]",
            });
            loginSuccess(false);
          }
        });
    }
  };
  const onFailure = (res) => {
    console.log("Referral Domain Login", res);
  };
  const openWindow = () => {
    let subDomain = window.location.hostname.split(".")[0];
    let referrerDetails = {
      subDomain: subDomain,
      referralCode: params?.refferalCode
        ? params?.refferalCode
        : getSession("refferal"),
      urlPath: window.location.href,
    };
    setCookie("referrerDetails", JSON.stringify(referrerDetails), 1);
    if (deployment === "uat") {
      window.location.href = `https://uat.hirestar.in/#/login/${subDomain}`;
    } else if (deployment === "prod") {
      window.location.href = `https://hirestar.in/#/login/${subDomain}`;
    } else {
      window.location.href = `http://localhost:3000/#/login/${subDomain}`;
    }
  };
  const decodeAccessToken = (access_token) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          auth: access_token,
        }
      )
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onFailure(error);
      });
  };
  const nativeDataRecived = (detail) => {
    if (count == 1) {
      if (detail?.accessDetails?.accessToken) {
        setTimeout(() => {
          decodeAccessToken(detail?.accessDetails?.accessToken);
        }, 2000);
      } else {
        alert("Access Token not found");
      }
    }
  };
  return (
    <>
      {modalVisible ? (
        <ErrorModal
          errormessage={errormessage}
          close={() => setModalVisible(false)}
          visibile={modalVisible}
        />
      ) : null}
      <CustomGoogleLoginButton
        clientId={hirestarclientId}
        scope={"profile email"}
        onSuccess={onSuccess}
        onFailure={onFailure}
        prompt="select_account"
        render={(data) => {
          return (
            <Button
              className={`${btnClassName ? "google-sign-btn" : ""}`}
              onClick={() => {
                if (window.ReactNativeWebView) {
                  window.spartan.spartanSend("googleIn");
                  window.spartan.spartanElm.addEventListener("getData", (e) => {
                    count = count + 1;
                    nativeDataRecived(e.detail);
                  });
                } else {
                  let domainCondition = [
                    "localhost",
                    "hirestar",
                    "uat",
                    "www",
                  ].includes(window.location.hostname.split(".")[0]);
                  if (domainCondition) {
                    data.onClick();
                  } else {
                    setValidDomain(true);
                    openWindow();
                  }
                }
              }}
            >
              {icon ? (
                <img
                  className="google-sign-icon"
                  src={GoogleLogoIcon}
                  alt="google-logo-icon"
                />
              ) : null}
              {googleBtnText}
            </Button>
          );
        }}
      />
      {/* <GoogleLogin
        clientId={clientId}
        autoLoad={autoLoad}
        // isSignedIn={location.pathname.includes("login") ? true : false}
        render={(renderProps) => (
          <Button
            className={`${btnClassName ? "google-sign-btn" : ""}`}
            onClick={() => {
              if (!renderProps.disabled) {
                renderProps.onClick();
              } else {
                setValidDomain(true);
                openWindow();
              }
            }}
            // disabled={renderProps.disabled}
          >
            {icon ? (
              <img
                className="google-sign-icon"
                src={GoogleLogoIcon}
                alt="google-logo-icon"
              />
            ) : null}
            {googleBtnText}
          </Button>
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
        prompt="select_account"
        // cookiePolicy={"single_host_origin"}
        // responseType="code"
        // accessType="offline"
        // uxMode="redirect"
        // redirectUri="http://localhost:3000/#/"
      /> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  jwtToken: state.jwtToken,
});
export default connect(mapStateToProps, {
  updateFormData,
  updateJwtToken,
  setloginFlag,
  updateIsLoading,
})(GoogleSignIn);
