import React from "react";

//Styles
import "./ErrorModal-style.css";

//Images

//Components
import Modal from "../Modal";
import Button from "../Button";

function ErrorModal({
  visibile,
  errormessage = "We apologize, something went wrong with your request. Please try again.",
  close,
}) {
  return (
    <Modal visibile={visibile} style={{ backgroundColor: "rgb(0 0 0 / 70%)" }}>
      <div className="error-modal">
        <div className="error-modal-message-container">
          <p>{errormessage.message}</p>

          <p style={{ fontSize: "15px" }}>{errormessage.code}</p>
        </div>
        <Button style={{ width: "130px" }} onClick={close}>
          Dismiss
        </Button>
      </div>
    </Modal>
  );
}

export default ErrorModal;
