import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import WelcomeSection from "../components/WelcomeSection";
import DeveloperCategories from "./DeveloperCategories";
import WhyTeknoPointSection from "../components/WhyTeknoPointSection";
import { updateReferralCode } from "../redux/actions/referral-action";
import InterviewProcessInfoGraphics from "../components/InterviewProcessInfoGraphics/InterviewProcessInfoGraphics";
import { getSession } from "../utilites/SessionFns";
import HowToHirestar from "../components/HowToHirestar";

const Home = ({
  refferalName,
  refferalDetails,
  fetchUserReferral,
  setRefferalName,
  referral,
  updateReferralCode,
  userId,
}) => {
  let queries = queryString.parse(useLocation().search);

  const params = useParams();

  const continueRef = useRef();

  useEffect(() => {
    if (params?.refferalCode) {
      fetchUserReferral(params?.refferalCode);
      setRefferalName(params?.refferalCode);
      sessionStorage.setItem("refferal", JSON.stringify(params?.refferalCode));
    }
    if (queries?.ref) {
      sessionStorage.setItem("refferal", JSON.stringify(queries?.ref));
      setRefferalName(queries?.ref);
    }
  }, [
    fetchUserReferral,
    params?.refferalCode,
    queries?.ref,
    setRefferalName,
    refferalName,
  ]);

  return (
    <div>
      <WelcomeSection
        refferalDetails={refferalDetails}
        onContinueClick={() =>
          continueRef.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <HowToHirestar />
      <InterviewProcessInfoGraphics
        homeDisplay={true}
        steps={
          !userId ? "6 steps to your dream job!" : "5 steps to your dream job!"
        }
        continueRef={continueRef}
      />
      <DeveloperCategories
        refferalDetails={refferalDetails}
        refferalName={refferalName}
      />
      <WhyTeknoPointSection
        refferalDetails={refferalDetails}
        refferalName={refferalName}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  referral: state.ref,
  userId: state.formData.userId,
});

export default connect(mapStateToProps, { updateReferralCode })(Home);
