import React, { useEffect, useState } from "react";
import axios from "axios";
const useCustomGoogleLogin = ({
  clientId,
  scope,
  onSuccess = () => {},
  onFailure = () => {},
  prompt,
}) => {
  //google client state
  const [client, setClient] = useState();

  //get request access token
  const getRequestAccessToken = () => {
    client.requestAccessToken();
  };

  //get decoded data
  const decodeAccessToken = (access_token) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          auth: access_token,
        }
      )
      .then((response) => onSuccess(response))
      .catch((error) => onFailure(error));
  };

  //initialize google
  const initilizeGoogle = () => {
    /* global google */
    if (google ? google : false) {
      const gClient = google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: scope,
        callback: (response) => {
          if (response?.access_token) {
            decodeAccessToken(response?.access_token);
          } else {
            
            onFailure(response);
          }
        },
        prompt: prompt,
      });
      // getOtherDetails(gClient);
      setClient(gClient);
    } else {
      console.log("Google script is loading...");
    }
  };

  //initilize google client
  useEffect(() => {
    // setTimeout(() => {
    initilizeGoogle();
    // }, 3000);
  }, []);

  return { getRequestAccessToken };
};

export default useCustomGoogleLogin;
