
import { combineReducers } from 'redux';

import loginFlagReducer from './login-flag-reducer';
import formDataReducer from './form-data-reducer';
import jwtTokenReducer from './jwtToken-reducer';
import isLoadingReducer from './isLoading-reducer';
import referralCodeReducer from './referral-reducer';

const rootReducers = combineReducers({
   loginFlag: loginFlagReducer,
   formData: formDataReducer,
   jwtToken: jwtTokenReducer,
   isLoading: isLoadingReducer,
   ref: referralCodeReducer,
});

export default rootReducers;