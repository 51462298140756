
import './button-style.css';

const Button = ({ children, className="", inverted = false, style, ...otherProps }) => {
   return (  
      <button className={`button ${className}`}
         style={{ backgroundColor: inverted ? 'var(--secondaryColor)' : 'var(--primaryColor)',...style }}
         {...otherProps}
      >
         {children}
      </button>
   );
}
 
export default Button;