import React, { useState } from "react";

//styles
import "./share-referral-modal.css";

//images
import closeIcon from "../../assets/images/close-icon.svg";
import gmailIcon from "../../assets/images/gmail.png";
import whatsappIcon from "../../assets/images/WhatsApp-logo.png";
import facebookIcon from "../../assets/images/Facebook-logo.png";
import twitterIcon from "../../assets/images/twitter.png";
import copyUrl from "../../assets/images/copyUrl.svg";
import shareUrl from "../../assets/images/shareUrl.svg";

import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

const ShareReferralModal = ({ close, url }) => {
  const [copiedState, setCopiedState] = useState(false);
  const handleLinkCopy = () => {
    setCopiedState(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopiedState(false);
    }, 1000);
  };
  return (
    <div className="share-container">
      <h3>Share URL on this</h3>
      <img
        src={closeIcon}
        className="share-close-icon"
        onClick={close}
        alt="close-icon"
      />
      <div className="icon-container">
        <div className="share-icon-container">
          <EmailShareButton
            style={{ display: "flex" }}
            url={url}
            // subject="Share Link of the refferal Url generated"
            subject="Job opportunities at Tekno Point Pvt Ltd"
            body="Hey! 
            I would like to refer you to Hirestar Portal, Please visit the referral link below to apply for the job in 6 easy steps.
            Link : 
            "
          >
            <img src={gmailIcon} alt="gmail-icon"></img>
          </EmailShareButton>
        </div>

        <div className="share-icon-container">
          <WhatsappShareButton
            style={{ display: "flex" }}
            title="Hey! 
            I would like to refer you to Hirestar Portal, Please visit the referral link below to apply for the job in 6 easy steps.
            Link : 
            "
            url={url}
          >
            <img src={whatsappIcon} alt="whatsapp-icon"></img>
          </WhatsappShareButton>
        </div>

        <div className="share-icon-container">
          <FacebookShareButton
            style={{ display: "flex" }}
            url={url}
            title="Hey! 
            I would like to refer you to Hirestar Portal, Please visit the referral link below to apply for the job in 6 easy steps.
            Link : "
            hashtag="Testing"
          >
            <img src={facebookIcon} alt="facebook-icon"></img>
          </FacebookShareButton>
        </div>

        <div className="share-icon-container">
          <TwitterShareButton
            style={{ display: "flex" }}
            url={url}
            title="Hey! 
            I would like to refer you to Hirestar Portal, Please visit the referral link below to apply for the job in 6 easy steps.
            Link : 
            "
            via="Refferal link for this institution"
            hashtags={["testing"]}
          >
            <img src={twitterIcon} alt="twitter-icon"></img>
          </TwitterShareButton>
        </div>
      </div>

      <div className="url-generate-containerBox">
        <p className="share-popup-separator">OR</p>
        <h3 className="copy-link-class">Copy Link</h3>
        <div className="url-containerBox">
          <img
            src={shareUrl}
            className="sharepopup-shareIconBox"
            alt="share-button"
          ></img>
          <p className="url-containerBox-link">{url}</p>
          <button onClick={() => handleLinkCopy()} className="url-btnBox">
            <img
              src={copyUrl}
              className="url-btn-imageBox"
              alt="copy-button"
            ></img>
          </button>
          <div className={`tool-tip-container ${copiedState ? "show-tool-tip" : ""}`}>
            <p style={{ textAlign: " center", fontSize: "small" }}>Copied</p>
            <div className="down-arrow-tooltip"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareReferralModal;
