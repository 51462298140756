import { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import DeveloperCateory from "../../components/DeveloperCateory";
import ErrorModal from "../../components/ErrorModal";
import { updateIsLoading } from "../../redux/actions/isLoading-action";

import ArrowIcon from "../../assets/images/leftArrow.svg";

import "./developer-categories-style.css";
import { getSession } from "../../utilites/SessionFns";

class DeveloperCategories extends Component {
  //Fetch JobData.

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      activeJobs: [],
      modalVisible: false,
      errormessage: "Its Error",
      refferalDetailsState: {
        jobs: [],
        errorMessage: "",
      },
      // refferalData: getSession('refferal')
    };
  }
  fetchJobOpeningsFunction() {
    this.props.updateIsLoading(true);
    axios
      .get("/shaft/api/job-management/get-job-details/v1")
      .then((resp) => {
        this.props.updateIsLoading(false);
        if (resp.data.status === "success") {
          this.setState({
            data: resp.data.response,
            activeJobs: resp.data.response.jobDetails
              .filter((job) => job.isActive === "yes")
              .filter((obj) => {
                let expiryDate = new Date(obj.expiry);
                let currentDate = new Date();
                return expiryDate > currentDate;
              })
              .map((category) => ({
                title: category.title,
                corelanguages: category.shortDescription,
                category,
                jobDetailsId: category.id,
              })),
          });
          this.filterJobs();
        } else {
          this.setState({
            modalVisible: true,
            errormessage: {
              message:
                "We are facing some technical issue, please again try after some time.",
              code: "[U-0004-B]",
            },
          });
        }
      })
      .catch((err) => {
        this.props.updateIsLoading(false);
        this.setState({
          modalVisible: true,
          errormessage: {
            message:
              "We are facing some technical issue, please again try after some time.",
            code: "[U-0004-A]",
          },
        });
      });
  }

  filterJobs() {
    let { refferalDetails, refferalName } = this.props;
    let { activeJobs } = this.state;
    if (refferalName) {
      if (refferalDetails?.isActive == "Y") {
        if (refferalName.includes("@")) {
          this.setState({
            refferalDetailsState: {
              jobs: activeJobs,
              errorMessage: "",
            },
          });
        } else {
          if (refferalDetails.isJobSelective == "N") {
            this.setState({
              refferalDetailsState: {
                jobs: activeJobs,
                errorMessage: "",
              },
            });
          } else {
            let filterJobs = [];
            refferalDetails.jobOpeningList.map((obj) => {
              filterJobs.push(
                ...activeJobs.filter((job) => job.jobDetailsId == obj)
              );
            });

            this.setState({
              refferalDetailsState: {
                jobs: filterJobs,
                errorMessage: "",
              },
            });
          }
        }
      } else {
        this.setState({
          refferalDetailsState: {
            jobs: [],
            errorMessage: `Hello ${
              getSession("userDetails")?.formData?.name ?? "User"
            }, Referral Link has been in de-activated, please reach out to the administrator for further assistance.`,
          },
        });
      }
    } else {
      this.setState({
        refferalDetailsState: {
          jobs: activeJobs,
          errorMessage: "",
        },
      });
    }
  }

  componentDidMount() {
    this.fetchJobOpeningsFunction();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refferalDetails !== this.props.refferalDetails) {
      // this.updateAndNotify()
      this.filterJobs();
    }
  }

  renderArrow = ({ type, onClick }) => {
    return (
      <div
        className={type === "PREV" ? "leftArrow" : "rightArrow"}
        onClick={onClick}
      >
        {type === "PREV" ? (
          <p className="prevArrow">
            <img src={ArrowIcon} alt="" />
          </p>
        ) : (
          // <img src={ArrowIcon} alt="" />
          <p className="nextArrow">
            <img src={ArrowIcon} alt="" />
          </p>
        )}
      </div>
    );
  };

  render() {
    console.log("Active Jobs", this.state.activeJobs);

    let jsonParser = [];

    if (this.state.data) {
      jsonParser = this.state.data?.jobDetails
        ?.map((category) => ({
          title: category.title,
          corelanguages: category.shortDescription,
          category,
          jobDetailsId: category.id,
        }))
        .filter((obj) => {
          let expiryDate = new Date(obj.category.expiry);
          let currentDate = new Date();
          return expiryDate > currentDate;
        });
    }

    let isAvailableJob;

    if (this.props.refferalName) {
      if (this.props.refferalName.includes("@")) {
        // isAvailableJob = jsonParser?.every(
        //   (obj) => obj?.category?.refferedBy?.length === 0
        // );
        isAvailableJob = false;
      } else {
        if (
          this.props.refferalDetails?.isActive === "N" ||
          this.props.refferalDetails === undefined
        ) {
          isAvailableJob = true;
        }
      }
    } else {
      isAvailableJob = false;
    }

    let refferalDistinguish;
    if (this.props.refferalName) {
      if (this.props.refferalDetails?.isJobSelective === "N") {
        refferalDistinguish = false;
      } else {
        refferalDistinguish =
          this.props.refferalName && !this.props.refferalName.includes("@");
      }
    } else {
      refferalDistinguish = false;
    }
    return (
      <div>
        <div className="main-container">
          {this.state.modalVisible ? (
            <ErrorModal
              errormessage={this.state.errormessage}
              close={() => this.setState({ modalVisible: false })}
              visibile={this.state.modalVisible}
            />
          ) : null}

          <div>
            <h2 className="current-openings-heading">
              <span>Select Job from Current Openings</span>
            </h2>
            <div className="dcs-container">
              {/* {isAvailableJob ? (
                <div className="no-job-opening-container">
                  <h2 className="no-job-opening-container-text">
                    {this.props.refferalDetails?.isActive === "N"
                      ? `Hello ${
                          getSession("userDetails")?.formData?.name ?? "User"
                        }, Referral Link has been in de-activated, please reach out to the administrator for further assistance.`
                      : "No Job Openings Available!!"}
                  </h2>
                </div>
              ) : refferalDistinguish ? (
                jsonParser
                  ?.filter(
                    (obj) => obj?.category?.isActive.toLowerCase() === "yes"
                  )
                  ?.filter((obj) =>
                    obj?.category?.refferedBy?.includes(this.props.refferalName)
                  ).length === 0 ? (
                  <div className="no-job-opening-container">
                    <h2 className="no-job-opening-container-text">
                      No Job Openings Available!!
                    </h2>
                  </div>
                ) : (
                  jsonParser
                    ?.filter(
                      (obj) => obj?.category?.isActive.toLowerCase() === "yes"
                    )
                    ?.filter((obj) =>
                      obj?.category?.refferedBy?.includes(
                        this.props.refferalName
                      )
                    )
                    ?.map((category, index) => (
                      <DeveloperCateory
                        key={index}
                        index={index}
                        {...category}
                      />
                    ))
                )
              ) : (
                jsonParser
                  ?.filter(
                    (obj) => obj?.category?.isActive.toLowerCase() === "yes"
                  )
                  ?.map((category, index) => (
                    <DeveloperCateory key={index} index={index} {...category} />
                  ))
              )} */}
              {this.state.refferalDetailsState.errorMessage !== "" ? (
                <div className="no-job-opening-container">
                  <h2 className="no-job-opening-container-text">
                    {this.state.refferalDetailsState.errorMessage}
                  </h2>
                </div>
              ) : this.state.refferalDetailsState.jobs.length === 0 ? (
                <div className="no-job-opening-container">
                  <h2 className="no-job-opening-container-text">
                    No Job Openings Available!!
                  </h2>
                </div>
              ) : (
                this.state.refferalDetailsState.jobs.map((obj, index) => (
                  <DeveloperCateory key={index} index={index} {...obj} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ isLoading: state.isLoading });
export default connect(mapStateToProps, { updateIsLoading })(
  DeveloperCategories
);
