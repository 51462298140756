import React, { useState } from "react";

import closeIcon from "../../assets/images/close-icon.svg";
import "./popup-traning-video-style.css";
import editIcon from "../../assets/images/editIcon.svg";
import reviewVideo from "../../assets/images/review-video-icon.svg";

const PopupTraningVideo = ({
  iframeSrc,
  close,
  showUpdateModal = null,
  multiChoose = false,
  videosDetails,
  singleFlag,
  status,
}) => {
  const [active, setActive] = useState(
    videosDetails?.interview ? "interview" : "assignment"
  );
  const [initialIframeSrc, setInitialIframeSrc] = useState(iframeSrc);

  return (
    <div className="popup-traning-video-container">
      {showUpdateModal &&
      (status.toLowerCase() === "pending" ||
        status.toLowerCase() === "applied") ? (
        <div
          onClick={() => showUpdateModal(active)}
          className="pt-edit-icon-container"
        >
          <img src={editIcon} alt="edit"></img>
        </div>
      ) : null}
      <div className="pt-close-icon-container" onClick={close}>
        <img
          src={closeIcon}
          alt="close-icon"
          className="close-icon-container"
        />
      </div>

      <div className="pt-video-iframe-container">
        {multiChoose && (
          <div className="pt-user-videos-container">
            {videosDetails?.interview && (
              <div
                className={`pt-user-video-container ${
                  active === "interview" ? "video-con-selected" : ""
                } `}
                onClick={() => {
                  setActive("interview");
                  setInitialIframeSrc(videosDetails.interview);
                }}
              >
                <img src={reviewVideo} alt="video-icon" />
                <p>Interview Video</p>
              </div>
            )}
            {videosDetails?.assignment && (
              <div
                className={`pt-user-video-container ${
                  active === "assignment" ? "video-con-selected" : ""
                } `}
                onClick={() => {
                  setActive("assignment");
                  setInitialIframeSrc(videosDetails.assignment);
                }}
              >
                <img src={reviewVideo} alt="video-icon" />
                <p>Assignment Video</p>
              </div>
            )}
          </div>
        )}

        {iframeSrc ? (
          <iframe
            src={initialIframeSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;picture-in-picture"
            allowFullScreen
          />
        ) : null}
      </div>
    </div>
  );
};

export default PopupTraningVideo;
