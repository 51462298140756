import React, { useState } from "react";
import "./how-to-hirestar.css";

//images
import laptopImg from "../../assets/images/laptopFrame.png";
import videoPlay from "../../assets/images/video-player-icon.svg";

const HowToHirestar = () => {
  let [youtubeVideoClick, setYoutubeVideoClick] = useState(false);
  return (
    <div className="main-container">
      <div className="howToHirestarContainer">
        <div className="laptop-video-container">
          {youtubeVideoClick ? (
            <>
              <img
                className="laptop-img"
                src={laptopImg}
                alt="loading image error"
              />
              <iframe
                className="youtube-video"
                width="346"
                height="220"
                src="https://www.youtube.com/embed/D8HEq0BWeXU?autoplay=1&listType=playlist&rel=0&vq=hd720"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              ></iframe>
            </>
          ) : (
            <>
              <img
                className="laptop-img"
                src={laptopImg}
                alt="loading image error"
              />
              <img
                src="https://img.youtube.com/vi/D8HEq0BWeXU/sddefault.jpg"
                className="youtube-video"
                onClick={() => {
                  setYoutubeVideoClick(true);
                }}
              />
              <img
                style={{ position: "absolute", cursor: "pointer" }}
                src={videoPlay}
                alt="video play"
                onClick={() => {
                  setYoutubeVideoClick(true);
                }}
              />
            </>
          )}
        </div>
        <div className="how-to-text-container">
          <h1 className="title-text" style={{ marginBottom: "10px" }}>
            How to apply for a job on Hirestar?
          </h1>
          <p className="subtitle-text">
            Check out the quick 2 minutes tutorial.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToHirestar;
