
import { FORM_DATA, formDataObj } from '../actions/form-data-action';


const formDataReducer = (state = formDataObj, action) => {
   switch (action.type) {
      case FORM_DATA:
         return { ...state, ...action.payload }
      default:
         return state;
   }
}

export default formDataReducer;