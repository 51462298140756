import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// import logoImg from "../../assets/images/HireStarLogo3.svg";
import logoImg from "../../assets/images/logo.png";
import grayCrossIcon from "../../assets/images/gray-cross-icon.svg";

import "./mobile-navigation-style.css";
import { getSession } from "../../utilites/SessionFns";
import RefHeader from "../RefHeader/RefHeader";
import Modal from "../Modal";
import ShareReferralModal from "../ShareReferralModal";

const MobileNavigation = ({
  refferalDetails,
  mobileNavFooter,
  showMobileNav,
  refferalName,
  onDismiss = () => {},
}) => {
  const history = useHistory();
  const mobileNavRef = useRef();

  const location = useLocation();

  const [referrModal, setReferrModal] = useState(false);

  const userData = getSession("userDetails");

  let manageIsVisible = userData?.jobApplicationDetails?.length > 0 ?? false;

  const handleReferralModalChange = () => {
    setReferrModal(true);
  };

  useEffect(() => {
    const onBodyClick = (e) => {
      if (mobileNavRef.current) {
        if (!mobileNavRef.current.contains(e.target)) {
          onDismiss();
        }
      }
    };

    document.body.addEventListener("click", onBodyClick);

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, [onDismiss]);

  return (
    <div
      className={`body-background-container ${
        showMobileNav ? "show-mobile-navigation-container" : ""
      } `}
    >
      {referrModal ? (
        <Modal
          visibile={referrModal}
          style={{ backgroundColor: "rgb(0 0 0 / 70%)" }}
        >
          <ShareReferralModal
            url={userData.formData.ref !== "" ? userData.formData.ref : ""}
            close={() => setReferrModal(false)}
          />
        </Modal>
      ) : null}

      <div
        className={`mobile-navigation-container ${
          showMobileNav ? "show-mobile-navigation-container" : ""
        }`}
        ref={mobileNavRef}
      >
        <div>
          <div className="mn-header-container">
            {refferalDetails?.logo !== "" &&
            refferalDetails?.logo !== null &&
            refferalDetails?.logo !== undefined ? (
              <RefHeader
                refferalDetails={refferalDetails}
                refferalName={refferalName}
              ></RefHeader>
            ) : (
              <div
                className="mn-logo-container"
                onClick={() => {
                  if (!location.pathname.includes("login")) {
                    onDismiss();
                    history.push("/");
                  }
                }}
              >
                <img
                  style={{ maxHeight: "50px" }}
                  className="logo"
                  src={logoImg}
                  alt="logo"
                />
              </div>
            )}

            <div className="mn-cross-icon-container" onClick={onDismiss}>
              <img src={grayCrossIcon} alt="cross-icon" />
            </div>
          </div>

          <div className="mn-links-conatiner" onClick={onDismiss}>
            <ul>
              {refferalDetails?.referralPageConfigurations?.navigations?.map(
                (obj) => {
                  return (
                    <li>
                      <a
                        href={obj.url}
                        target="_blank"
                        style={{ color: "inherit" }}
                        rel="noreferrer"
                      >
                        {obj.label}
                      </a>
                    </li>
                  );
                }
              )}
              {userData?.formData?.userId ? (
                <>
                  <li onClick={() => history.push("/profile")}>My Profile</li>
                  {userData.formData.ref !== "" && (
                    <li onClick={handleReferralModalChange}>
                      Refer your friend
                    </li>
                  )}
                </>
              ) : null}
              {manageIsVisible ? (
                <li onClick={() => history.push("/manage")}>Manage</li>
              ) : null}

              <li>
                <a
                  href="https://teknopoint.in/company/about-us/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mn-footer-container">{mobileNavFooter()}</div>
      </div>
    </div>
  );
};

export default MobileNavigation;
