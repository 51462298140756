import React, { useEffect, useState } from "react";

//Images
import checkMarkCircle from "../../assets/images/checkMarkCircle.svg";
import closeCircleIcon from "../../assets/images/closeCircleIcon.svg";

//Styles
import "./ApplicationSuccessfulModal.css";

//Utilities
import { getSession } from "../../utilites/SessionFns";

//Components
import Modal from "../Modal";

const ApplicationSuccessfulModal = ({
  visibile,
  close,
  applicationStatus,
  finalStatus,
}) => {
  //UserData
  const userData = getSession("userDetails");

  //States
  const [registeredDate, setRegisteredDate] = useState("");

  //UseEffect
  useEffect(() => {
    let registeredDate = new Date(applicationStatus?.createdOnDate);
    const ExpiringMonth = registeredDate.toLocaleString("default", {
      month: "long",
    });
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    let endDate = ` ${registeredDate.getDate()}${nth(
      registeredDate.getDate()
    )} ${ExpiringMonth}  ${registeredDate.getFullYear()}`;
    setRegisteredDate(endDate);
  }, [applicationStatus?.createdOnDate]);
  return (
    <Modal visibile={visibile} style={{ backgroundColor: "rgb(0 0 0 / 80%)" }}>
      <div className="pop-up-container">
        <div className="inner">
          <img
            src={closeCircleIcon}
            className="close-icon"
            onClick={close}
            alt="x"
          />
          <div>
            <span className="highlight">Dear {userData?.formData?.name},</span>
          </div>
          {/* <div>
            <span className="para">
              You have successfully applied for this job. Your Job Application
              ID is <strong>{applicationStatus.jobApplicationId}</strong> and
              you have applied for &nbsp;
              <strong>{applicationStatus.title}</strong> Dated on
              {registeredDate}.
            </span>
          </div> */}
          <div>
            <span className="para">
              Congratulations. Thank you for your interest in us. You have
              successfully applied for{" "}
              <strong>{applicationStatus.title}</strong> dated on
              {registeredDate}. Your Job Application ID is{" "}
              <strong>{applicationStatus.jobApplicationId}</strong>
            </span>
          </div>
          <div className="final">
            <span>Status -</span>
            <div className="applied-container">
              {/* <span className="highlight">{applicationStatus.status}</span> */}
              <span className="highlight">{finalStatus}</span>
              <img className="icon-check-mark" src={checkMarkCircle} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicationSuccessfulModal;
